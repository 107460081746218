import React, { useContext } from 'react'
import {BackButton, ButtonComponent} from '../../components'
import { support } from '../../assets/images'
import { useNavigate } from 'react-router-dom'
import { StateContext } from '../../context/stateContext'
import { url } from '../../config'

const SupportPage = () => {
  const navigate = useNavigate()
  const {darkMode} = useContext(StateContext)
  return (
    <div className={`${darkMode ? 'dark-mode' : ""}`}>
      <BackButton size={24} text='Support' />
      <div className='p-20'>
          {/* header : back, title */}

          <div className='d-flex space-evenly'>
              <img style={{marginTop:-40}} src={support} alt='support city2guide' />
          </div>
              
          {/* <div className='p-10 bg-lightgray border-rounded d-flex items-center'>
              <PiMagnifyingGlass />
              <input className='text-lightgray px-20 bg-transparent border-none' placeholder='How we can help you ?'></input>
          </div> */}

          {/* <p className='my-20 dark-mode-text'>Search from our vast and informational documentations </p> */}

          <h3 className='fw-500 dark-mode-text'>Quick Links</h3>
          <br />
          <p onClick={()=>navigate('/ContactUs')} className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5'>Customer Care</p>
          <p className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5' onClick={()=>navigate('/Best-Practices')}>Do's And Dont's</p>
          <p onClick={()=>navigate('/Emergency')} className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5'>Emergency Helpline</p>
          <p className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5' onClick={()=>navigate('/FAQ')}>FAQ</p>
          <p className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5' onClick={()=>navigate('/Privacy-Policy')}>Privacy Policy</p>
          {/* <p onClick={()=>navigate('/Emergency')} className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5'>Report</p> */}
          {/* <p onClick={()=>navigate('/Terms-Conditions')} className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5'>Terms & Conditions</p> */}
          <p onClick={()=>window.open(url + '/media/files/TermsAndConditions.pdf', '_blank')} className='cursor-pointer fw-400 fs-14 my-5 text-lightgray dark-mode-gray-text px-5'>Terms & Conditions</p>

          <br/>
          
          <p className='dark-mode-text'>We would love to hear from you ,</p>
          {/* <ButtonComponent title='Call Us' wrapperContainerStyle={{margin:'20px 0px 10px 0px'}}/> */}
          <ButtonComponent wrapperContainerStyle={{margin:"30px 0px"}} onClick={()=>navigate('/Feedback')} title='Write to Us'/>
      </div>
    </div>
  )
}

export default SupportPage