import React, { useContext, useState } from 'react'
import { additionalDocuments } from '../../assets/images'
import Wrapper from './Wrapper'
import { PiInfoBold } from 'react-icons/pi'
import { FileUpload } from '../../components'
import { StateContext } from '../../context/stateContext'

const AdditionalDocuments = () => {
    const [currentTab, setSelectedText] = useState('Tourist Facilitator Certificate')
    const {identityVerificationForm, setIdentityVerificationForm} = useContext(StateContext)


    const changeHandler = (key, text) => {
      if (text && typeof text === 'object' && text instanceof Blob) {
          setIdentityVerificationForm({...identityVerificationForm, [key]:text})
      }
      setIdentityVerificationForm({...identityVerificationForm, [key]:text})
    }

    return (
        <Wrapper image={additionalDocuments} slideIndex={2} lineLength={3} title='Additional Documents' descText='to avail free membership upto 3 months' nextButtonText="Save & Submit" nextButtonUrl='/Home' submit={true} identityHandler={true}>
        
            <div className="button-container">
                <div className={`slider ${currentTab === 'Tourist Facilitator Certificate' ? 'left' : 'right'}`}></div>
                <button className="toggle-button" style={{fontSize:'14px', color:`${currentTab === 'Tourist Facilitator Certificate' ? 'white' :'black' }`}} onClick={() => setSelectedText('Tourist Facilitator Certificate')}><span>Tourist Facilitator Certificate</span></button>
                <button className="toggle-button" style={{fontSize:'14px', color:`${currentTab === 'Police Clearance Certificate' ? 'white' :'black' }`}} onClick={() => setSelectedText('Police Clearance Certificate')}>Police Clearance Certificate</button>
            </div>

            {currentTab !== 'Police Clearance Certificate' &&
                    <>
                        <p className='text-center dark-mode-text'>Upload Tourist Facilitator Certificate to avail 90 days exclusive membership</p>
                        <FileUpload onChange={(e)=>changeHandler('touristFacilitatorCertificate', e.target.files[0])}  side='Tourist Facilitator Certificate'/>
                        <div className='d-flex flex-end my-10'>
                            <p className='fs-14 me-2 dark-mode-text'>Add a watermark</p>
                            <input type='checkbox' />
                        </div>
                    </>
            }
            {currentTab === 'Police Clearance Certificate' &&
                <>
                    <p className='text-center dark-mode-text'>Upload Police Clearance Certificate to avail 90 days exclusive membership</p>
                    <FileUpload onChange={(e)=>changeHandler('policeClearanceCertificate', e.target.files[0])} side='Police Clearance Certificate'/>
                    <div className='d-flex flex-end my-10'>
                        <p className='fs-14 me-2 dark-mode-text'>Add a watermark</p>
                        <input type='checkbox' />
                    </div>
                </>
            }


            <div className='bg-crimsonLight border-rounded p-20 d-flex space-between items-center'>
                <PiInfoBold size={80} color='crimson' className='me-2'/>
                <p className='fs-12 dark-mode-text'>
                    Uploading incorrect or morphed document is a serious offence of Forgenry under Indian Penal Code of IT Act, 2000
                    (IPC Section 66C & 66D).
                    It may lead to permanent ban from City2Guide Platform.
                </p>
            </div>


            <div className='d-flex flex-start items-center my-10'>
                <input className='me-2' type='checkbox' />
                <p className='fs-14 dark-mode-text'>I acknowledge all the information shared above are true and correct.</p>
            </div>

            <div className='d-flex flex-start items-center my-10'>
                <input className='me-2' type='checkbox' />
                <p className='fs-14 dark-mode-text'>I agree to obey the policy of city2guide.</p>
            </div>
            

           
          
        </Wrapper>
    )
}

export default AdditionalDocuments