import React, { useContext, useEffect, useState } from 'react'
import { BackButton, Card } from '../../components'
import { getAllMemberships, } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { StateContext } from '../../context/stateContext'
import './membershipspage.styles.scss'
const MembershipsPage = () => {
  const [selected, setSelected] = useState(undefined)
  const {darkMode} = useContext(StateContext)
  const [data, setData] = useState([])
  const {notify} = useNotify()

  useEffect(()=>{
    getAllMemberships().then(res => {
      setData(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  //eslint-disable-next-line
  },[])



  return (
    <div className={` ${darkMode ? 'dark-mode' : ''} px-5`}>
      <div className='d-flex space-between items-center'>
        <BackButton size={24} text='Membership' />
        {/* <ButtonComponent onClick={()=>setShowPromoCode(!showPromoCode)} wrapperContainerStyle={{margin:"0px 10px", padding:"4px 6px", borderRadius:"5px"}} style={{fontSize:14, color:"#fff"}} title='Apply Promo'/> */}
      </div>
      {/* {showPromoCode &&
        <div className='d-flex space-evenly w-100' style={{marginBottom:"-10px"}}>
          <div className='d-flex w-100 px-10'>
            <input value={promoCode} onChange={(e)=>setPromoCode(e.target.value)} placeholder='Enter promo code' style={{outline:"none"}} className='w-100 p-5 border-rounded' />
            <button onClick={()=>applyPromoHandler()} className='w-100 fw-600 border-rounded' style={{outline:"none", background:darkMode? 'white' : 'black', color:darkMode? 'black' : 'white'}}>Apply</button>
          </div>
        </div>
      } */}
      <div className='card-wrapper-container'>
        {data.map((item, index) => {
          return(
            <Card hidePurchaseButton={true}   key={`${index}-${item.title}`} title={item.title} desc={item.desc} price={item.price} index={index} selected={selected === index+4} setSelected={setSelected} _id={item.id} />
          )
        })}
      </div>
    </div>
  )
}

export default MembershipsPage