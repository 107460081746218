import React from 'react'
import './d-testimonials.styles.scss'
import { FaQuoteLeft } from "react-icons/fa";

const TestimonialsCard = ({title, desc, svg, image, name, createdAt }) => {
  return (
    <div className='desktop-testimonial-container'>

      <FaQuoteLeft />

      <h1>{title}</h1>

      <p className='desc'>{desc}</p>
      <div className='overflow-hidden'>
        <div style={{borderRadius:10, height:'100%', backgroundRepeat:"none", width:"100%", backgroundImage:`url('${svg}')`}} className='d-flex space-evenly'>
          <div className='d-flex column items-center'>
            <img style={{height:50, width:50, borderRadius:'50%', objectFit:"cover"}} src={image} alt='' />
            <p className='text-whitesmoke fs-20'>{name}</p>
            <p className='text-whitesmoke fs-12 fw-300'>{createdAt}</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default TestimonialsCard