import React from 'react'
import './chipcomponent.styles.scss'

const ChipComponent = ({text, selected, onClick, ...props}) => {

  return (
    <div onClick={onClick}>
      <button className={`cursor-pointer ${selected ? 'bg-active' : "bg-mute"}`} {...props}>
        {text}
      </button>
    </div>
  )
}

export default ChipComponent