import React, { useRef } from 'react';
import './otpcomponent.styles.scss';

const OtpComponent = ({ state, setState, setFocusedInput }) => {
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();

    const refs = [inputRef1, inputRef2, inputRef3, inputRef4];

    const changeHandler = (key, text, ref) => {
        if (state[key].length <= 0 || text?.length <= 1) {
            setState({ ...state, [key]: text });
            if (text.length !== 0) {
                ref?.current?.focus();
                if(setFocusedInput){
                    setFocusedInput((prev) => Math.min(prev + 1, 3)); // Move focus to the next input
                }
            }
        } else {
            ref?.current?.focus();
        }
    };

    return (
        <div className='otp-boxes-container'>
            {['otp1', 'otp2', 'otp3', 'otp4'].map((otpKey, index) => (
                <input
                    key={otpKey}
                    ref={refs[index]}
                    value={state[otpKey]}
                    onChange={(e) =>
                        changeHandler(otpKey, e.target.value, refs[index + 1])
                    }
                    onFocus={setFocusedInput ? () => setFocusedInput(index) : ()=>true}
                    type='number'
                    className='otp-box'
                />
            ))}
        </div>
    );
};

export default OtpComponent;
