import React from 'react'
import './bottomdrawer.styles.scss'
import { useSpring, animated } from '@react-spring/web';

const BottomDrawer = ({state, content, yValue=100}) => {
  const drawerAnimation = useSpring({
    transform: state ? 'translateY(0%)' : 'translateY(100%)',
    config: { tension: 100, friction: 15 }, // Spring-like configuration
  });
  return (
    <div>
      <animated.div className={`drawer-container ${state ? `open ${yValue ? `h-${yValue}` : ''}` : ''}`} style={drawerAnimation}>
        <div className="drawer-content">
          {content}
        </div>
      </animated.div>
    </div>
  )
}

export default BottomDrawer