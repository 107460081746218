import React, { useContext, useEffect, useState } from 'react'
import {BackButton, ButtonComponent, ModalContentContainer,} from '../../components'
import { PiArrowRight, PiDotsThreeOutlineVerticalThin, PiInfo, PiMapPinFill , PiPhoneCallFill, PiShareNetworkFill, PiWarning, PiX} from 'react-icons/pi'
import {TbPhoneCheck } from 'react-icons/tb'
import './myprofilepage.styles.scss'
import { animated, useSpring } from '@react-spring/web'
import { StateContext } from '../../context/stateContext'
import { guides } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import CountryFlag from 'react-country-flag';
import { LiaHandPointRightSolid } from 'react-icons/lia'
import { AiOutlineStop } from 'react-icons/ai'

const MyProfilePage = () => {
  const [mute, setMute] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [dotsClickCount, setDotsClickCount] = useState(0)
  const {darkMode, userInfo} = useContext(StateContext)
  const [guideDetail, setGuideDetail] = useState({})
  const { notify } = useNotify()
  const slideAnimation = useSpring({
    transform: !mute ? 'translateY(0%)' : 'translateY(-100%)',
    config: { tension: 100, friction: 10 }, // Spring-like configuration
  });

  const slideAnimation1 = useSpring({
    transform: !mute ? 'translateY(0%)' : 'translateY(1%)',
    config: { tension: 200, friction: 9 }, // Spring-like configuration
  });

  let timer;
  const handleCloseModal = () => {
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{
      if(showModal){
        setShowModal(false)
      }
    }, 50)
  }

  useEffect(()=>{
    guides(null, userInfo?.userDetailsId).then(res => {
      setGuideDetail(res?.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
    // eslint-disable-next-line
  },[])

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <animated.div onClick={()=>handleCloseModal()} className='my-profile-page-container' style={slideAnimation1}>
        {/* My Profile :  text */}
        <div className='my-20 d-flex space-between items-center position-relative'>
          <BackButton size={24} style={{marginLeft:'-10px'}}/>
          <h1 className='title-text dark-mode-text'>My Profile</h1>

          {/* option menu and its content */}
          <animated.div style={slideAnimation} className={`${showModal ? '' : 'hide'} modal-container-mypf border-rouded`}>
            <ModalContentContainer Icon={LiaHandPointRightSolid } text='Nudge' />
            <ModalContentContainer color Icon={PiWarning} text='Report' />
            <ModalContentContainer Icon={AiOutlineStop } text='Block' />
            <ModalContentContainer Icon={PiInfo} text='Help' />
          </animated.div>

          {dotsClickCount === 0 &&
            <PiDotsThreeOutlineVerticalThin className='dark-mode-text' onClick={()=>{setMute(!mute);setDotsClickCount(1)}} size={30}/>
          }
          {dotsClickCount === 1 && 
            <PiDotsThreeOutlineVerticalThin className='dark-mode-text' onClick={()=>{setShowModal(true);setDotsClickCount(2)}} size={30}/>
          }
          {dotsClickCount === 2 && 
            <PiX onClick={()=>{setShowModal(false);setDotsClickCount(0);setMute(!mute);}} className={`cursor-pointer vertical-dots custom-svg svg-block rounded-svg ${showModal ? 'bg-white' : ''}`}/>
            
          }
        </div>

        {/* Basic details container */}
        <div className='d-flex items-center'>
          
          {/* Image */}
          <img className='profile-image' src={guideDetail?.profileImage} alt='user' />
          
          {/* Profile details container */}
          <div className='d-flex column px-20'>
            <div className='d-flex items-center'>
              <h4 className='name dark-mode-text' style={{margin:'0px 4px'}}>{userInfo?.fullName}</h4>
              {guideDetail?.showCountryFlag &&
                <CountryFlag className='country-flag' countryCode={guideDetail?.countryCode} svg style={{ marginLeft:3, marginBottom:2, width: '18px', height: '18px', borderRadius:"0% !important" }} />
              }
            </div>
              
            
            <div className='d-flex my-2 items-center'>
              <p className='text-lightgray fs-14'>&nbsp;@</p>
              <p className='city fs-10'>&nbsp;{userInfo?.username}</p>
            </div>
            <div className='d-flex my-2 items-center'>
              <PiMapPinFill  size={16} color='var(--primary)' />
              <p className='city'>&nbsp;{guideDetail?.city}</p>
            </div>
            <div className='d-flex my-2 items-center'>
              <TbPhoneCheck fill='var(--primary)' strokeWidth={1} color='var(--primary)' />
              <p className='city'>&nbsp;Phone Verified</p>
            </div>
          </div>
        </div>

        {/* Info : text */}
        <div style={{overflow:"hidden"}}>
          <animated.h3 className={`subtitle-text dark-mode-text ${!mute ? '' : 'mute'}`} style={slideAnimation}>Info</animated.h3>

          <animated.div className={`info-card-container space-evenly ${!mute ? '' : 'mute'}`} style={slideAnimation}>
            {/* Left container */}
            <div className='left-container'>
              {guideDetail?.isGuide ?
              <>
                <p className='info-title'>Service Location</p>
                <p className='info-subTitle'>{guideDetail?.cityOfService}</p>
              </>
              :
              <>
              <p className='info-title'>Address</p>
              <p className='info-subTitle'>{guideDetail?.city},{guideDetail?.state}</p>
              </>
              }

              <p className='info-title'>Age</p>
              <p className='info-subTitle'>{new Date().getFullYear() - new Date(guideDetail?.dob).getFullYear() }</p>

              <p className='info-title'>Interest & Hobbies</p>
              <p className='info-subTitle'>{guideDetail?.hobbiesAndInterest?.split(",")?.join(', ', '')}</p>
            </div>
            {/* Right container */}
            <div className='right-container'>
              <p className='info-title'>Languages</p>
              <p className='info-subTitle'>{guideDetail?.languages?.map(item => (item.language+', '))}</p>

              <p className='info-title'>Gender</p>
              <p className='info-subTitle'>{guideDetail?.gender}</p>

              <p className='info-title'>Profession</p>
              <div className='d-flex items-center'>
                <p className='info-subTitle'>{guideDetail?.profession?.length > 0 ? guideDetail?.profession : guideDetail?.isStudent ? 'Student' : ''}</p>
                  {guideDetail?.justifyInFewWords?.length > 0 &&
                  <PiArrowRight className='px-3 info-subTitle'/>
                }
                <p className='info-subTitle'>{guideDetail?.justifyInFewWords}</p>
              </div>
            </div>
          </animated.div>
        </div>



        {/* About : text */}
        <h3 className='subtitle-text dark-mode-text'>About</h3>

        {/* Description : text */}
        <p className='about-desc-text dark-mode-text'>{guideDetail?.about}</p>

        {/* Contact & Social buttons */}
        <animated.div className={`d-flex my-20 ${!mute ? '' : 'mute'}`} style={slideAnimation}>
          <ButtonComponent Icon={PiPhoneCallFill} wrapperContainerStyle={{width:'50%', margin:'10px 4px 10px 0px'}} title='&nbsp;Contact'/>
          <ButtonComponent Icon={PiShareNetworkFill} wrapperContainerStyle={{width:'50%', margin:'10px 0px 10px 4px'}} title='&nbsp;Social' />
        </animated.div>

        {/* Gallary : text */}
        <h3 className='subtitle-text dark-mode-text'>Album</h3>

        {/* Image container */}
        <div className='image-container-mp'>
          {guideDetail?.image1 &&
            <img src={guideDetail?.image1} alt='img 1' />
          }
          {guideDetail?.image2 &&
            <img src={guideDetail?.image2} alt='img 1' />
          }
          {guideDetail?.image3 &&
            <img src={guideDetail?.image3} alt='img 1' />
          }
          {guideDetail?.image4 &&
            <img src={guideDetail?.image4} alt='img 1' />
          }
        </div>
      </animated.div>
    </div>
  )
}

export default MyProfilePage