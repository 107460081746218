import React, { useContext } from 'react'
import { StateContext } from '../../context/stateContext'
import { BackButton } from '../../components'
import { FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { deactivateAccount } from '../../utils/service'
import { useNotify } from '../../components/Toast'

const DeleteOrDeactivatePage = () => {
  const {darkMode, setUserInfo } = useContext(StateContext)
  const navigate = useNavigate()
  const {notify} = useNotify()
  const deactivateAccountHandler = () => {
    deactivateAccount().then(res => {
      notify('Account Deactivated')
      setUserInfo(false)
      localStorage.removeItem('auth-cg')
      window.location.reload()
    }).catch(err =>{
      notify(err.response &&  err.response.data.detail ? err.response.data.detail : err.message)
    })
  }
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <div>
        <BackButton text='Back'/>
        <h1 className='text-center my-20 dark-mode-text fw-600'>Delete Account</h1>

        <div className='d-flex space-evenly items-center'>
          <div className='d-flex items-center coulmn'>
            <FaTrash size={40} color='var(--primary)'/>
          </div>
          <div>
            <p className='dark-mode-gray-text fs-16'>You will permenantly lose your :</p>
            <ul className='px-20'>
              <li className='fs-14 my-4 dark-mode-gray-text'>Information</li>
              <li className='fs-14 my-4 dark-mode-gray-text'>Media</li>
              <li className='fs-14 my-4 dark-mode-gray-text'>Chats</li>
              <li className='fs-14 my-4 dark-mode-gray-text'>Account Verification</li>
            </ul>
          </div>
        </div>
        <div className='d-flex my-20 items-center px-20'>
          <button onClick={()=>navigate(-1)} className='fw-500' style={{width:"100%", ourline:"none", border:"1px solid lightgray", marginRight:10, backgroundColor:"white", padding:"10px", borderRadius:"10px"}}>Cancel</button>
          <button onClick={()=>deactivateAccountHandler()} className='fw-500' style={{width:"100%", ourline:"none", border:"none", backgroundColor:"var(--primary)", color:"white", padding:"10px", borderRadius:"10px"}}>Confirm</button>
        </div>

      </div>
    </div>
  )
}

export default DeleteOrDeactivatePage