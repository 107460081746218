import React, { useContext, useState } from 'react'
import { AccordionDefault, BackButton, Loader } from '../../components'
import { PiMagnifyingGlass } from 'react-icons/pi'
import { faqImage } from '../../assets/images'
import { StateContext } from '../../context/stateContext'
import { useNavigate } from 'react-router-dom'


const DATA = [
  { id:1, 
    title:'How do I contact a host on the platform?',
    content:`Still stuck with some questions about an experience or want to know more about your host? We get that. That is why you have the option to directly chat with our hosts via the City2Guide Chat! <br/>
            If you are looking for a personalized experience or want to ask a few things, pick the host you find interesting and click the ‘Request contact' or 'Request a personalized tour' button on guide’s profile. <br/>
            You also have the option to directly reach out and find more information about a host, what their interests are, and an introduction video, on their profile page. You can find a list of local guides by searching for a specific city or applying a filter.<br/>
            Make sure you are logged in on our platform in order to send a message to one of our local hosts.<br/>
            <b>City2Guide does not allow the exchange of personal contact details before the booking is confirmed due to our privacy policy.</b> <br/>
            `
  },
  { id:2, 
    title:'How do I make a booking?',
    content:`City2Guide is a peer-to-peer market place connecting local hosts with travellers from around the world!<br/>
          In order to book a City2Guide experience, follow these easy steps on our mobile website:<br/>
            •	Choose your destination or enter a city name in search bar to find a guide.<br/>
            •	(optional) Filter your search as per your preferences.<br/>
            •	Pick your favourite local city guide profile<br/>
            •	Know more about the guide like their area of expertise, services they offer, charges for tour.<br/>
            •	Once you are happy with your choice, complete your reservation by clicking the "Book now" button at the bottom of guide’s profile.<br/>
            •	For requesting to personalise your booking, click on the “Request for personalized tour” button.<br/>
            •	Kindly send a message to guide (host) for any queries or clarification. <br/>
          If you want to check some details before starting your experience. You can reach out to your local host directly via the City2Guide message interface chat feature. Your host will be able to answer all of your questions and give recommendations for an amazing local experience.<br/>
    `
  },
  { id:3, 
    title:'What is the cancellation policy?',
    content:`We're sorry to see that you wish to cancel your City2Guide experience! <br/>
            Did you know you could reschedule instead? Go to your Booking page, select the tour you wish to cancel, then tap on “Edit” icon & after rescheduling, tap on “Confirm” button.<br/>
            If you decide to cancel your booking, here is everything to know about our cancellation policy:<br/>
            We City2Guide do not charge any amount for cancellation on tour.<br/>
            Our refund policy was made to be transparent so your experience is as smooth as possible and gives you a fair go. In such cases we do not charge cancellation amount are as follows:<br/>
            -Reducing the number of guests on your booking.<br/>
            -Rescheduled bookings<br/>
            -Same day bookings<br/>
            -Cancelling an unconfirmed booking<br/>
            But exceptional on cancelling a confirmed booking: If a guest (tourist) wish to cancel booking within 24 hours after the booking is accepted/confirmed by host (guide). Henceforth, the tourist had already paid 10% charges of tour in-advance, then the refund will not be initiated to tourist. The guest may consider the charged amount as service availability fee of guide.<br/>
    `
  },
  { id:4, 
    title:'How can I add or remove guests from my booking?',
    content:`At City2Guide, we understand that flexibility is key when planning your experiences. Whether you're adding more guests to join in on the fun or need to adjust the size of your group, we're here to facilitate those changes smoothly. This guide outlines how to add guests to your booking and our approach to adjustments, ensuring your booking process is hassle-free.<br/>
            <b>Adding or Reducing Guests to Your Booking:</b><br/>
            When you decide to include or exclude more guests in your experience, here's how the process works:<br/>
            <b>Reach Out to Your Host </b>– Initiate the process by contacting your host directly through our platform's chat feature to request the addition of more guests by requesting a personalized offer.<br/>
            <b>New Booking Creation & Cancel Previous Tour </b>– The addition of new guests will result in a new booking for the extra participants. This new booking will be scheduled for the same date and time as your original booking.<br/>
            <b>Personalized Offer and Payment </b>– Your host will send you a personalized offer for the new booking, reflecting the number of additional guests and any price difference. Review the details of this offer, accept it, and complete the payment securely after successful tour completion.<br/>
            <b>Hassel-free booking modification process </b>– The new original booking will represent your entire party, ensuring everyone is included. This method keeps your experience organized and allows us to accommodate your group's needs efficiently.<br/>
            We always recommend that you inform your host about any changes, so that they have all the information they need to offer you the best possible experience!<br/>
            <br/>
            <b>Complaints</b><br/>
            Any claim or complaint against City2Guide in respect of the service must be promptly submitted, but in any event within 14 days after the reserved event has taken place. Any claim or complaint that is submitted after the 14 days period, may be rejected and the claimant shall forfeit his/her right to any (damage or cost) compensation.<br/>

    `
  },
  { id:5, 
    title:'How do I reset or recover my password?',
    content:`You can change your password from City2Guide login page & tap on “Reset Password”.<br/>
            Type your mobile number & enter OTP. Wait a minute to receive a reset password link on email & mobile number. In case if you did not receive a mail, we suggest you to check spam box.<br/>
      `
  },
  { id:6, 
    title:'How do I delete my account?',
    content:`We are sorry to hear that you wish to delete your account. You must login to delete.<br/>
          After clicking menu icon (top right of screen) at the homepage, tap on “Settings”.<br/>
          Then tap on “Delete or Deactivate Account” and proceed to complete.<br/>
          When you delete your account, you will be logged out immediately on all devices and your account will be disabled.<br/>
    `
  },
  { id:7, 
    title:'What happens if my host cancels my reservation?',
    content:`In case your host is no longer available for a confirmed booking, the first option will always be to reschedule. Your host will offer you the opportunity to choose another time or date in their calendar directly, so that you can still do the experience together!<br/>
              If rescheduling is not an option, your original host will cancel. If another host is available for the same experience at the booking time and date, you will be assigned a new host for the experience! That means that you will receive the same experience but from a different host.<br/>
              Or else you can search by your own steps they are as follows:<br/>
                •	Choose your destination or enter a city name in search bar to find a guide.<br/>
                •	(optional) Filter your search as per your preferences.<br/>
                •	Pick your favourite local city guide profile<br/>
                •	Know more about the guide like their area of expertise, services they offer, charges for tour.<br/>
                •	Once you are happy with your choice, complete your reservation by clicking the "Book now" button at the bottom of guide’s profile.<br/>
                •	For requesting to personalise your booking, click on the “Request for personalized tour” button.<br/>
                •	Kindly send a message to guide (host) for any queries or clarification. <br/>
              If you want to check some details before starting your experience. You can reach out to your local host directly via the City2Guide message interface chat feature. Your host will be able to answer all of your questions and give recommendations for an amazing local experience.<br/>
              In the unfortunate case your experience is cancelled and no other host (guide) is available. We will inform you when the guide is available for your desired city.<br/>
      `
  },
  { id:8, 
    title:'How can I cancel my booking?',
    content:`If you cannot attend an experience on the booked date anymore, you have the option to reschedule your booking or to cancel it.<br/>
              A booking can be rescheduled multiple times, as long as the date has not already passed!<br/>
              <b>To cancel your booking</b>, follow these simple steps:<br/>
              1.	Go to your booking page and select the booking that you wish to cancel.<br/>
              2.	Click on the “Edit” button then click on “Cancel Tour”.<br/>
              3.	Enter a reason for cancellation and the tap on “Submit” button<br/>
              Remember, you can always reschedule your booking as long as the date has not passed!<br/>
              <b>Important note:</b> the cancellation will only be considered once you have clicked the Cancel button and have validated your choice. Agreeing on a cancellation with your host or requesting it via email will not affect the status of your booking and will not count for the application of the cancellation policy.<br/>
          `
  },
  { id:9, 
    title:'Where do I find my booking details?',
    content:`The details of your booking can be found under the Booking page at homepage of your City2Guide profile when you are logged into the website, on the upper right side of the page. Once you tap on 'Calendar' icon, your future bookings and all the information you need are shown, and you can find information about past bookings under the 'Past bookings' tab.<br/>
            Your booking overview contains information such as: date, number of guests and starting time & duration of tour.<br/>
            If you have any questions about your booking, we recommend that you get in touch with your host directly as they will be able to help you with your booking, the route of the experience, but also information about their city for example.<br/>
            `
  },
  { id:10, 
    title:'I lost my booking confirmation, where do I find it?',
    content:`You can find an overview of your booking details such as date, starting time, the number of guests and the payment details at ‘Booking Page’ & ‘Notification’ section. To access them, you only need to be logged into the City2guide website.`
  },
  { id:11, 
    title:'How do I prepare for my experience?',
    content:`It's very exciting that your experience and your trip are starting soon!<br/>
            There are a few things you can do to be ready for your experience so that you can enjoy it to the fullest.<br/>
            We strongly recommend that you confirm the meeting point for the tour or confirm the pick-up with your host. If you are not sure how to reach the meeting point by yourself, your host will be happy to help you with information about the city and the most convenient way to reach the meeting point.<br/>
            In general, reaching out to your host beforehand is always a good idea! They can indicate any special equipment that you may need. We also recommend that you share with your host in advance any special requirement you may have.<br/>
            You're ready to enjoy a wonderful City2Guide experience!<br/>
      `
  },
  { id:12, 
    title:'What is City2Guide Review Policy?',
    content:`We love getting reviews! In order to let you submit a review as easily as possible, please take a look at our review policy.<br/>
              When you leave a review, please keep your review relevant to your City2Guide experience, and focus on your interactions with your local host. <br/>
              The following content is not allowed:<br/>
              •	Comments about your experience with the City2Guide Product, when it’s not related to your experience with the host you are rating (e.g. City2Guide service, booking process)<br/>
              •	Comments about social, religious or political views of yourself or your host (guide)<br/>
              •	Comments and judgements about your host’s personality or character in a negative way<br/>
              •	Swearing or other profanity<br/>
              •	Use reviews as a form of extortion, such as getting a refund or any other compensation<br/>
              •	Write a review as a competitor in order to influence City2Guide in a negative way.<br/>
              Any of the above reviews will be deleted by City2Guide.<br/>
            For any queries and complaint, you can also contact us through via WhatsApp or write a mail at support@city2guide.com<br/>
      `
  },
  { id:13, 
    title:"I have submitted my booking request. What's next?",
    content:`The City2Guide system will forward your request to the tour guide for confirmation. Please note that your booking is not confirmed until the guide has accepted it and you have received the confirmation email from City2Guide.<br/>
            Your booking status will be “pending” until the guide accepts your request.<br/>
            If you don't receive the confirmation email within 48 hours, or if you have any questions or concerns, please don't hesitate to send a message to your guide or contact our Customer Support<br/>
      `
  },
  { id:14, 
    title:"I am planning a last-minute trip and have no time to do research on tours. What can I do?",
    content:`If you need a tour in the next 24 hours, you may want to try our “Instant Confirm” option.<br/>
              You can go to search bar, apply a filter ‘Instantly Confirm’ under Availability section and ‘Enter the city’. The list of guides will appear who had opt-in for short notice tour requests. The itinerary of these tours is focused on city highlights and it’s perfect for first-time visitors. You can book a guide of your choice as per your interest & experience.<br/>
              `
  },
  { id:15, 
    title:"None of the tours I see meet my needs. What are my options?",
    content:`If you don't see exactly what you are looking for, message any guide in the destination you’re interested in and they can create a custom private tour just for you.<br/>
            Click on the 'Message' button at the bottom of the guide's profile.<br/>
            Every guide is happy to work with you on creating an itinerary and related pricing that will suit your schedule and unique interests.<br/>
            All you need to do is ask and the guide will reply you back.<br/>

    `
  },
  { id:16, 
    title:"Once my tour is confirmed, how do I contact the guide? ",
    content:`If you'd like to communicate with your guide prior to your tour, the best way to contact them is by using the message system in your City2Guide account.<br/>
            Taking into consideration the guides’ full schedules, time zone differences and the busy nature of being an in-demand guide in high season, hardworking guides find that having a conversation before requesting for a tour is the most effective way to meet multiple needs in a balanced and efficient manner. Additionally, having a written record of the tour arrangements is helpful to all parties and we can assist both parties if necessary.<br/>
            Please note we also offer 24/7 customer support including a high-priority emergency line. This is also available on your booking confirmation<br/>
    `
  },
  { id:17, 
    title:"Who can see my profile?",
    content:`We City2Guide believes in user privacy and we do have a set of limitation for security purpose. Therefore, only a guest (tourist) can see guide’s profile and vice versa.
    `
  },
  { id:18, 
    title:"Who are City2Guide?",
    content:`We help people travel better by connecting curious travellers with exceptional local city guides, all over the India. Our mission is to curate the best experiences from every country people travel in Incredible India. `
  },
  { id:19, 
    title:"Do I need to tip my guide?",
    content:`No. You do not need to tip your guide; it is not expected. However, the guide may accept a tip, if offered. All the guides set their tour prices to ensure they are appropriately compensated for their time. As such they do not rely on tips from tours.<br/>
            All guides are aware of this policy. If you would like to help your guide, you can leave a thoughtful review of your tour for future travellers to see.<br/>
    `
  },
  { id:20, 
    title:"Did not received a reply / response back from my guide, what should I do?",
    content:`We expect guides to reply within 24-48 hours to every traveller inquiry. If it's been more than 48 hours since you sent your email or DM, we encourage you to contact directly via ‘contact info’ shared in the guide’s profile <br/>
            Please note: answers to questions regarding specific details about the tour (i.e. pricing, transportation, itinerary, etc.) must come from the guide.<br/>
            Customer Support will assist in facilitating prompt email contact between yourself and the guide; however, to set proper expectations, it should be noted that during high season, response times from the guides will depend on volume of inquiries and bookings. Guides typically conduct their administrative duties during their off-hours in order to deliver the very best tour experiences possible.<br/>
            We thank you for your patience and understanding during these busy seasons.<br/>

    `
  },
  { id:21, 
    title:"What should I do if my tour cannot go ahead as planned on the day?",
    content:`If for any reason there is an issue on the day of your tour which would stop the tour going ahead as planned (i.e. a member of your group is unwell, you cannot find your tour guide, etc..) then please call our dedicated contact number at +918591214121<br/>
            Alternatively, you can also contact us through via WhatsApp or write a mail at support@city2guide.com<br/>
            A member of our support staff will be able to assist you immediately and provide you the options available to you.<br/>
    `
  },
  { id:22, 
    title:"Can I pay the guide in cash on the day of the tour?",
    content:`Payment should be done after the tour is completed.<br/>
              Yes, you may communication to guide regarding this as per guide’s preference.<br/>
              If you have any questions please contact us.<br/>
    `
  },
  { id:23, 
    title:"What should I do If I feel unsafe during my tour?",
    content:`Your safety and security are extremely concern to your private guide and City2Guide.<br/>
            If you feel unsafe, communicate your concerns to your guide immediately.<br/>
            You have the right to stop the tour at any time. At your request, your guide will return you to the agreed ending point by the transportation method or any comfortable way possible.<br/>
            Please report the incident immediately using SOS emergency button under menu list (side drawer) for emergency help line number at any hour contact methods at : +918591214121 via call or whatsapp.<br/>
            Alternatively, you may reach our Customer Support team via any of our contact methods.<br/>
    `
  },
  { id:24, 
    title:"Do you provide insurance to cover my tour?",
    content:`No, we recommend each traveller purchases their own travel insurance before going on vacation to ensure you are fully covered.<br/>
            City2Guide is not a party to any agreement between the guest and the host and is not liable for any (attributable) failure to perform or other damages arising out of this relationship. We acts solely as an intermediary between the guest and the guide. We are a marketplace where demand (travellers) meets supply (local hosts). While we hope your tour and vacation go smoothly, we understand that things don’t always go according to plan and we want you to be able to relax and enjoy your time with the knowledge you are covered.<br/>
            Do you have any doubt? Your host will be able to answer all your questions and give recommendations on the direct message / chat.<br/>
    `
  },
  { id:25, 
    title:"What kind of checks / verification do you perform on the guides?",
    content:`We at City2Guide strongly value the quality of our hosts and experiences.<br/>
              Before we accept an application, we first conduct the following:<br/>
              - Verified contact & mobile number<br/>
              - Background checks & profile identity proof (Name, Age, Gender & Residence) verification by government.<br/>
              - In-depth interview by video, phone and/or in-person to know potential hosts before they can be online.<br/>
              We are creating and nurturing a strong community in every destination we are present. Our most experienced and performing local hosts also play a vital role in making sure that the quality standards meet the expectation of our guests.<br/>
          `
  },
  { id:27, 
    title:"How do I know the host (guide) can provide a 5-Star experience?",
    content:`We require each tour guide (host) to accept our Tour Guide Agreement which contains several obligations & norms they have to follow.<br/>
            While the tour guide is responsible for the actual tour development & experiences they deliver, we provide best practices so that no details are forgotten for 100% customer satisfaction.<br/>
            Every guest gets the opportunity to review their experience with a host. Reviews are an indication for the guests but also a quality check for City2Guide. The best way of course, is to read the ratings and comments by travellers who have taken the guide's tours.<br/>
            We recommend & hope you'll also try out new tour guides who don't yet have any ratings (and then rate them yourself!). They also have a unique story to tell - otherwise they wouldn't be part of the City2Guide community :)<br/>
          `
  },
  { id:28, 
    title:"What happens if my guests (travellers) are not able to attend the experience?",
    content:`If your guests are no able to attend the booking, they are recommended to reschedule for another date and time. A booking can be rescheduled instantly before the starting time of the experience. <br/>
          If your guests cannot find a suitable alternative date or time, they need to cancel the booking. <br/>
          If a guest informs you directly about a potential cancellation, and asks you to cancel it, please make sure that it is the guest's own responsibility to cancel the booking. <br/>
        `
  },
  { id:29, 
    title:"How long should you wait for a guest?",
    content:`We understand that sometimes guests may run late. In case this happens, we encourage you to contact the guest and try to find a solution (e.g. rescheduling the tour). If rescheduling is not possible and, because of the delay, it is not possible to conduct the tour in the time left, we classify this situation as a last-minute guest cancellation.<br/>
            We also want to remind you that guests who have paid for a 3-hour tour may be willing to accept a shorter tour if they're running late. We encourage open communication between hosts (guide) and guests (traveller) to discuss any potential changes in schedule and ensure that everyone is on the same page. Ultimately, it's up to the host to decide how long they wish to wait for their guest.<br/>
          `
  },
  { id:30, 
    title:"Is a personalized experience always private?",
    content:`Yes, all experiences at City2Guide are always private. In other words, a private experience is an experience for 1 or more guests who have planned to be together in the same experience (E.g. family, group of friends, colleagues, etc.).
    `
  },
  { id:31, 
    title:"What do I do to become a local city host (guide)?",
    content:`To qualify to be a City2Guide host, we expect you to know your city like the back of your hand and to love your city to the core. You should also have a good level of communication skills & knowledge to host international guests (tourist), and of course, enough availability to host the tours regularly.
    `
  },
  { id:32, 
    title:"How much will I earn as a City2Guide host?",
    content:`How much you earn will depend on the number of bookings you receive. This goes hand in hand with the number and type of experience you want to host and your availability for it. The pricing of each experience is independent on your own. We City2Guide do not charge any convenience fee. We recommend you to renew membership before it ends for smooth functioning to accept booking.
    `
  },
  { id:33, 
    title:"How do I receive advance payments?",
    content:`The moment you are accepted to be a host, you'll be asked to create a profile and sign-in. On the side bar {side bar icon} go to ‘Identity Profile Verification’, kindly complete the Profile ID & Bank Verification respectively Page 1 & Page 2. After the successful ID & Bank Verification you are eligible to receive the advance payment. There, you can let us know the best way to receive your payments (we offer bank transfer payments & UPI).
    `
  },
  { id:34, 
    title:"Can I add my own tours & experiences to the City2Guide platform?",
    content:`We’re opening the door for you to let your creativity shine! As a City2Guide host (guide) you can design and offer your very own tours, crafted from your unique perspective and intimate knowledge of your city. By creating a personal tour, you can offer guests a one-of-a-kind experience, tailored to your expertise and passion. These tours will be showcased right above ‘Book’ button on your profile, ensuring that guests (tourists) from around the world can discover and book your exceptional experiences. Join us in this new adventure and let’s craft unforgettable memories together!
    `
  },
  { id:35, 
    title:"What services does City2Guide offer me as a host?",
    content:`City2Guide gives hosts a platform to offer experiences to travellers from around the world. To enable travellers to find hosts and have an excellent customer experience, City2Guide delivers several services:<br/>
            1.	We make your dream a (paid) reality by giving you the right tools and an easy to use platform to receive and manage bookings.<br/>
            2.	We help you take part in responsible tourism of all experience levels from beginners as well as professional guides & promotion of our hosts through many different marketing and partner channels for more opportunity.<br/>
            3.	Help in promoting your brand and online presence as host experiences tour category for every interest. Therefore, we City2Guide is a free-to-join platform that empowers locals like you to earn money hosting private experiences that show the real side of their city away from mass commercial tourism.<br/>
            4.	No commission, no hidden charges and no convenience fee by City2Guide.<br/>
            5.	Payment security and compensation in case of no-shows or last-minute cancellation from the traveller according to the cancellation policy.<br/>
            6.	We are proactive community with outstanding customer service for hosts and travellers.<br/>
    `
  },
  { id:36, 
    title:"Does offering my experiences on platform mean working for City2Guide?",
    content:`City2Guide is a peer-to-peer marketplace advertising tours and activities that are provided by passionate locals. While being a host at City2Guide you work on a freelance basis and there is no employment agreement between the two parties.<br/>
            We value our host community, and we want to ensure our commercial collaborative relationship is a long lasting one. It's important the both parties are aware of the mutual rights and responsibilities.<br/>
            As host, you are responsible for the following:<br/>
            •	Managing your bookings and timely communication with the customers<br/>
            •	Making arrangements / planning to provide services<br/>
            •	Declaring your taxes as a freelancer according to the local laws<br/>
            •	Arranging your personal insurance while providing the services (individual choice)<br/>
            •	Promote to receive booking and tour activities for more guest & have a global appeal.<br/>

    `
  },
  { id:37, 
    title:"Why should I apply to become a host?",
    content:`Our mission is to connect people and cultures. We do that by breaking down barriers between travellers and locals worldwide. We enable locals to earn their fair share with the passion they have for their city while creating experiences for travellers that they’ll remember for the rest of their lives. <br/>
            <b>Why choose City2Guide?</b><br/>
            All our experiences are 100% private, can be 100% personalized, and are 100% authentic.<br/>
            We promote sustainable tourism, with an emphasis on creating value for local communities, and giving our guests the chance to have a positive impact locally and represent our Incredible India. 
            <br/>
    `
  },
  { id:38, 
    title:"Who can become a City2Guide host?",
    content:`City2Guide is a peer-to-peer marketplace advertising tours and activities that are provided by individuals.
    `
  },
  { id:39, 
    title:"What is City2Guide looking for in a host?",
    content:`There is no requirement as such to be a City2Guide host, we expect you to know your city like the back of your hand and to love your city to the core. You should also have a good level of communication skills & knowledge to host international guests, and of course, enough availability to host the tours regularly. We want to make sure that you have enough experience in your city to showcase your stories and knowledge to the guests (travellers) all around the world. 
    `
  },
  { id:40, 
    title:"Can businesses offer experiences on City2Guide?",
    content:`There is no option to have a corporate profile on our website. Requirements for our hosts:<br/>
            •	The individual showing on the profile and experience on City2Guide. This person will always be the one providing the experience and services and is the person who interacts with our guests.<br/>
            •	For those who advertise their services on City2Guide, you are free to have your own business but please avoid corporate attributes as logo, company name, etc. That is not allowed on our website.<br/>
            •	As a host, you are committed to represent City2Guide while interacting with the guests. Don’t sell any other services that are not advertised through the City2Guide platform initially.<br/>

    `
  },
  { id:41, 
    title:"What does my work as a host look like?",
    content:`As a City2Guide host, you are a freelancer. That means you can manage your own schedule and your own availability. The only requirement is that you be available to deliver your tours & commitment to your guests. 
    `
  },
  { id:42, 
    title:"Will my guest be able to leave a review in the case of a no-show?",
    content:`We value transparency and want to let you know that all reviews, including negative ones, will be published. This means that if a tour didn't happen because the guest was late, they can still leave a review. We want to ensure that all guests can share their experiences, both positive and negative.
    `
  },
  { id:43, 
    title:"I already have a full-time job; can I still be a host?",
    content:`City2Guide is a peer-to-peer marketplace that advertises your services as a host. You are a freelancer and therefore free to manage your own availability and adjust it to your personal schedule. In addition, availability is essential to receiving bookings!
    `
  },
  { id:44, 
    title:"Can I host an experience together with other people?",
    content:`City2Guide works with individual locals who want to show their city and share their passions. In order to become a host on City2Guide you need to guarantee that the person who is meeting the guests is the same person who has a profile on the website. Only if the tour or activity that you promote on City2Guide requires multiple people to be present at the time of the experience, kindly inform the traveller (guest) regarding the same to avoid confusion and discomfort.
    `
  },
  { id:45, 
    title:"As a guide, I had extra expenses while providing services to my guests, what do I do?",
    content:`While hosting on City2Guide you are fully in control of the expenses you budgeted for your offers. In case the guest is requesting something extra you can easily add the new costs in a personalized offer and send it to your guests to confirm and pay for. In this case there are no surprises during the experience that you are going to have together.<br/>
            In case the guest is asking for extras during the experience, you should be clear about the extra costs for the additional food, drinks or an extended duration, for example. The unforeseen items need to be covered on the spot by the guest.<br/>
    `
  },
  { id:46, 
    title:"How do guests leave reviews?",
    content:`Your reviews will come from guests who have booked & done an experience with you. Every guest who books directly through City2Guide receives an email asking them to review their host (and 2 reminders).<br/>
            When they do so, the reviews will be published on your profile and you will receive an email notification that you received a new review. <br/>
            After the tour, remind your guests personally to leave you a review about their experiences and satisfaction!<br/>
    `
  },
  { id:47, 
    title:"How do I contact my guests?",
    content:`Once a booking is confirmed, you can get in touch with your guests via message or phone. For unconfirmed bookings, you can use the chat to respond to guest requests.<br/>
            Guests love to hear from you after they made a booking. Let them know you are excited too! Reach out to them and ask them about their interests so you can offer an amazing personalized experience.<br/>
            Travellers can also get in touch with you directly via our platform, with a direct message or a personalized offer request. Guest messages and personalized offer requests will appear in the ‘Messages’ tab of your account.<br/>
            Guests (tourist) often contact hosts (guide) with questions about the activity, location, pricing, availability, etc. Make sure to reply to guest messages as instantly as possible to reassure your guests and secure your bookings!<br/>
    `
  },
  { id:48, 
    title:"How do I access and fill my Guide (host) profile?",
    content:`Your profile is essential to your success as a City2Guide host. Make sure that it's attractive to guests, shows the best of your personality and knowledge, and that it's updated regularly!<br/>
            Make sure to fill in all the sections because these information is very valuable for your guests. Each and every detail will make your profile more applauding.  <br/>

    `
  },
  { id:49, 
    title:"Why do I need to complete an ID verification as a City2Guide host?",
    content:`As the City2Guide host community continues to grow globally, we need to ensure the safety of both our hosts (guide) and our guests (tourist). We also need to conduct additional verification steps to safely process payments to our hosts. <br/>
            We had a dedicated team for verifying the identity of users in a secure and easy way to keep our platform safe, reliable & sustainable. The security of the data of our users is very important to us in this process.<br/>
            This data will not be passed on to other parties, unless legally required (in case of reported incidents related to an experience or government interference).<br/>

    `
  },
  { id:50, 
    title:"What should I do if my ID verification was unsuccessful?",
    content:`After you submit your ID for verification, our system conducts checks to verify that the document submitted is valid and meets our security standards.<br/>
            If your verification is unsuccessful, you will be able to try again after 24 hours. We will notify you with an email if this is the case and direct you to the ID verification section of your profile.<br/>
            To ensure a successful verification, you need to take a picture of a valid photo ID, like your Aadhar card, PAN card, Passport, Drivers license, or national ID, checking that:<br/>
            •	Should not expired<br/>
            •	Must be clear & easy to read, with no blur or glare<br/>
            •	The whole ID information should be visible in the picture<br/>
            Please note that scanned and black & white pictures of documents will not be accepted. For some documents (like driver’s licenses) it's necessary to take a picture of both the front and the back of the document.<br/>
    `
  },
  { id:51, 
    title:"What is the City2Guide fee?",
    content:`
          No commission, no hidden charges and no convenience fee by City2Guide.
          You only need to renew membership to avail our features & services
      `
  },
  { id:52, 
    title:"Report a conversation or profile",
    content:`
          At City2Guide, we strive to ensure that all interactions within our community—whether you're a host (guide) or a guest (tourist) are respectful, safe, and enjoyable. We understand that, on rare occasions, conversations may not align with these values. To address this, the City2Guide chat has the ability for both hosts and guests to report conversations they find inappropriate. This article explains how to use the "Report" feature and what happens after a report is submitted.<br/>
          <span class='text-primary'>When to Report a Conversation or Profile</span><br/>
          You should consider reporting a conversation if it contains any of the following:<br/>
          •	Hate Speech: Language that promotes hate or violence against individuals based on race, religion, gender identity, sexual orientation, etc.<br/>
          •	Inappropriate Behaviour: Messages that are sexually explicit, threatening, or excessively rude.<br/>
          •	Spam: Unwanted or repetitive messages that do not pertain to the experience or service being offered.<br/>
          •	Fraud: Attempts to deceive or scam users, including phishing attempts or misleading information.<br/>
          <span class='text-primary'>How to Report a Conversation or Profile</span><br/>
          1.	Open the City2Guide message screen and go to the conversation you wish to report.<br/>
          2.	Look for 3 vertical dots at top right of the screen and tap on ‘Report’ option.<br/>
          3.	Select a Reason: After clicking "Report", you'll be prompted to select a reason for the report. Choose the option that best describes your concern (e.g., Hate Speech, Inappropriate Behaviour, Fake Profile, Scam Fraud, etc).<br/>
          4.	Once you've selected a reason then tap on “submit” button to report.<br/>
          5.	If you wish to report a guide, then go to guide’s profile page and repeat the 2nd, 3rd, & 4th step.<br/>
          What Happens Next?<br/>
          All reports are reviewed by the City2Guide team. We take each report seriously and will investigate the content of the conversation in question. For hosts reporting a conversation will ensure that it does not affect your in-time response rate. We understand that you may not wish to engage with or respond to inappropriate messages.<br/>
          Depending on the findings of our review, appropriate actions will be taken. This could range from warning the offending user, suspending their account, or in severe cases, reporting the behaviour to relevant authorities.<br/>
          After filing a report both you and the account you reported will no longer be able to interact via the City2Guide platform.<br/>
          Confidentiality and Support<br/>
          Please be assured that all reports are treated with confidentiality. The identity of the person reporting the conversation will not be disclosed to the other party. If you're unsure whether to report a conversation or need further assistance, our support team is here to help. You can reach out via our help centre or contact support directly for guidance.<br/>

      `
  },
  { id:52, 
    title:"Be Safe, Secure & Sensible",
    content:`
         City2Guide is dedicated to connecting you with locals all over Incredible India, offering personalized experiences that aim to enrich your travel experiences. As you prepare to go on your next adventure, we want to highlight the importance of booking a guide & pay for membership to enhance the travel experiences luxuriously by private tour guide who will help you explore the city and make your day hassle-free. This process not only enhances your experience but also ensures your peace of mind. Let’s dive into why this is a smart choice for every traveller.<br/>
<br/>
        <span class='text-primary'>Safety and Security</span><br/>
        Your well-being is a primary concern for us at City2Guide. Booking and paying for the membership for our platform gives you access to a network of verified local city guides. We conduct thorough checks to ensure these guides meet our standards, providing you with a sense of security as you explore new destinations. Additionally, our secure payment system safeguards your personal and financial information, protecting you from potential fraud and security breaches. This level of safety allows you to focus more on the enjoyment of your travels.<br/>
        <br/>
        <span class='text-primary'>Money and Refund Guarantee</span><br/>
        Travel plans can sometimes be unpredictable, and we get that. When you book and pay via City2Guide, you benefit from our straightforward refund policy. In case you need to cancel, our policy is designed to be clear and fair, offering you a level of financial protection. This approach gives you the flexibility to make bookings with the assurance that you have a safety net in unforeseen circumstances. Additionally, there is no convenience / processing fee, no hidden charges & no commission of monetary transaction between the tourist (guest) and host (guide).<br/>
        <br/>
        <span class='text-primary'>Policy and Compliance</span><br/>
        Following our booking and payment policies is essential for a smooth City2Guide experience. These policies are in line with international travel standards and local laws, ensuring your tours are not only enjoyable but also adhere to ethical and legal guidelines. By booking through City2Guide, you contribute to sustainable tourism practices that positively impact local communities and the environment.<br/>
<br/>
        Choosing to book a local city tour guide on City2Guide platform is a wise step towards a fulfilling travel experience. It's about more than convenience; it's about safeguarding your journey, securing your finances, and supporting responsible tourism. As you venture into the world of unique travel experiences with City2Guide, remember these measures are there for your benefit. We’re here to help make your travels as smooth and enjoyable as possible in Incredible India. Enjoy your journey with City2Guide, where we’re committed to making your travel experiences both memorable and secure.<br/>

      `
  },
]

const FAQPage = () => {

  const {darkMode} = useContext(StateContext)
  const [data, setData] = useState(DATA)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  let timer = null;
  
  const handleChange = (text) => {
    setLoading(true)
    if(text.length < 3){
      setLoading(false)
      return setData(DATA)
    }
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{
      const results = DATA.filter(item => item.title.toLowerCase().includes(text.toLowerCase()))
      setData(results)
      setLoading(false)
    }, 700)
  }
  
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <div className='px-10'>

          <BackButton size={24} style={{marginLeft:-10}} text='FAQ'/>
          
          <h1 className='fw-600 text-center my-10 text-primary'>Welcome to FAQ</h1>

          <div className='p-10 bg-lightgray border-rounded d-flex space-between items-center'>
              <input onChange={(e)=>handleChange(e.target.value)} className='faq-input text-lightgray px-10 bg-transparent border-none w-100' placeholder='Search your questions here '></input>
              <PiMagnifyingGlass />
          </div>
          <div className='d-flex space-evenly'>
            <img src={faqImage} alt='FAQ'/>
          </div>
          
          {loading ? 
            <Loader />
          :
            <>
              {data.length === 0 &&
              <div className='d-flex space-evenly'>
                <p onClick={()=>navigate('/ContactUs')} className='p-2 my-10 border-rounded border text-primary'>Contact City2Guide</p>
                <p onClick={()=>navigate('/Feedback')} className='p-2 my-10 border-rounded border text-primary'>Share Feedback</p>
              </div>
              }
              <AccordionDefault items={data} />
            </>
          }
      </div>
    </div>
  )
}

export default FAQPage
