import React from 'react'
import './icondropdown.styles.scss'

const IconDropDown = ({Icon, children, ...props}) => {
  return (
    <div className='d-flex items-center icon-dropdown-container' {...props}>
      {Icon &&
        <Icon className='dark-mode-text' size={30} />
      }
      {children}
    </div>
  )
}

export default IconDropDown