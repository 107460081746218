import React, { useContext, useEffect } from 'react'
import { identityVerification } from '../../assets/images'
import Wrapper from './Wrapper'
import { PiInfoBold, PiLightbulb } from 'react-icons/pi'
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";
import { FileUpload, IconInput } from '../../components';
import { StateContext } from '../../context/stateContext';
import { getUserDocuments } from '../../utils/service';

const IdentityVerification = () => {
  const {identityVerificationForm, setIdentityVerificationForm} = useContext(StateContext)

  const changeHandler = (key, text) => {
    if (text && typeof text === 'object' && text instanceof Blob) {
        setIdentityVerificationForm({...identityVerificationForm, [key]:text})
    }
    setIdentityVerificationForm({...identityVerificationForm, [key]:text})
  }


  useEffect(()=>{
    getUserDocuments().then(res=> {
      setIdentityVerificationForm({
        'adhaarCard':res.data.adhaarCard,
        'adhaarCardFront':res.data.adhaarCardFront,
        'adhaarCardBack':res.data.adhaarCardBack,
        'panCard':res.data.panCard,
        'panCardFront':res.data.panCardFront,
        'panCardBack':res.data.panCardBack,
        'accountHolderName':res.data.accountHolderName,
        'bankName':res.data.bankName,
        'accountNumber':res.data.accountNumber,
        'ifscCode':res.data.ifscCode,
        'branchName':res.data.branchName,
        'passbookPhoto':res.data.passbookPhoto,
        'touristFacilitatorCertificate':res.data.touristFacilitatorCertificate,
        'policeClearanceCertificate':res.data.policeClearanceCertificate,
        'upi':res.data.upi,
        'accountType':res.data.accountType
      })
    }).catch(err => {
      console.log('')
    })
    // eslint-disable-next-line
  },[])


  return (
    <Wrapper image={identityVerification} slideIndex={0} lineLength={3} title='Identity Verification' nextButtonText="Save & Continue" nextButtonUrl='/Profile-Verification/bank-details-verification'>
        <div className='bg-lightgray border-rounded p-20 d-flex space-between items-center'>
            <PiLightbulb size={50} className='me-2'/>
            <p className='fs-12'>These documents are important to get a verification Badge in your profile. 
            It may take upto 6 hours to verify.</p>
        </div>
        <h3 className='fw-500 my-20 dark-mode-text'>Adhaar Card</h3>
        <div className='my-20'>
            <IconInput type='number' value={identityVerificationForm?.adhaarCard} onChange={(e)=>changeHandler('adhaarCard', e.target.value)} Icon={HiOutlineDocumentMagnifyingGlass} placeholder='Adhaar Card' EndIcon={PiLightbulb}/>
        </div>
        <FileUpload disabled={identityVerificationForm?.isVerified} value={identityVerificationForm?.adhaarCardFront} onChange={(e)=>changeHandler('adhaarCardFront', e.target.files[0])}/>
        <FileUpload disabled={identityVerificationForm?.isVerified} value={identityVerificationForm?.adhaarCardBack} side='Back'  onChange={(e)=>changeHandler('adhaarCardBack', e.target.files[0])}/>
        <div className='d-flex flex-end'>
          <p className='fs-14 me-2 dark-mode-text'>Add a watermark</p>
          <input type='checkbox' />
        </div>

        <h3 className='fw-500 my-20 dark-mode-text'>Pan Card</h3>
        <div className='my-20'>
            <IconInput value={identityVerificationForm?.panCard} onChange={(e)=>changeHandler('panCard', e.target.value)} Icon={HiOutlineDocumentMagnifyingGlass} placeholder='Pan Card' EndIcon={PiLightbulb}/>
        </div>
        <FileUpload value={identityVerificationForm?.panCardFront} onChange={(e)=>changeHandler('panCardFront', e.target.files[0])}/>
        <FileUpload value={identityVerificationForm?.panCardBack} side='Back' onChange={(e)=>changeHandler('panCardBack', e.target.files[0])}/>
        <div className='d-flex flex-end'>
          <p className='fs-14 me-2 dark-mode-text'>Add a watermark</p>
          <input type='checkbox' />
        </div>
        <br />
        <div className='bg-crimsonLight border-rounded p-20 d-flex space-between items-center'>
          <PiInfoBold size={80} color='crimson' className='me-2'/>
            <p className='fs-12 dark-mode-text'>
                Uploading incorrect or morphed document is a serious offence of Forgenry under Indian Penal Code of IT Act, 2000
                (IPC Section 66C & 66D).
                It may lead to permanent ban from City2Guide Platform.
            </p>
        </div>
        <div className='d-flex flex-start items-center my-10'>
          <input className='me-2' type='checkbox' />
          <p className='fs-14 dark-mode-text'>I acknowledge all the information shared above are true and correct.</p>
        </div>
        <div className='d-flex flex-start items-center my-10'>
          <input className='me-2' type='checkbox' />
          <p className='fs-14 dark-mode-text'>I agree to obey the policy of city2guide.</p>
        </div>
        <br/>
    </Wrapper>
  )
}

export default IdentityVerification