import React, { useContext, useEffect, useState } from 'react'
import { BackButton, ButtonComponent, Card } from '../../components'
import { memberships, validatePromoCode } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { StateContext } from '../../context/stateContext'

const MembershipPage = () => {
  const [selected, setSelected] = useState(undefined)
  const [promoCode, setPromoCode] = useState('')
  const [showPromoCode, setShowPromoCode] = useState(false)
  const {darkMode, userInfo} = useContext(StateContext)
  const [data, setData] = useState([])
  const [discount, setDiscount] = useState(null)
  const [remainingDays, setRemainingDays] = useState(0)
  const [activeMembershipId, setActiveMembershipId] = useState(0)
  const {notify} = useNotify()

  useEffect(()=>{
    memberships().then(res => {
      setData(res.data.data)
      if(res?.data?.is_active){
        setRemainingDays(res.data.remainingDays)
        setActiveMembershipId(res.data.activeMembership)
      }

    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  //eslint-disable-next-line
  },[])

  const applyPromoHandler = () =>{
    if(promoCode.length < 3){
      return notify("Please enter a valid promo code")
    }
    validatePromoCode(promoCode).then(res => {
      setDiscount(res.data)
      notify('Promo code applied 🎉')
    }).catch(err => {
      return notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  return (
    <div className={` ${darkMode ? 'dark-mode' : ''} px-5`}>
      <div className='d-flex space-between items-center'>
        <BackButton size={24} text='Membership' />
        <ButtonComponent onClick={()=>setShowPromoCode(!showPromoCode)} wrapperContainerStyle={{margin:"0px 10px", padding:"4px 6px", borderRadius:"5px"}} style={{fontSize:14, color:"#fff"}} title='Apply Promo'/>
      </div>
      {showPromoCode &&
        <div className='d-flex space-evenly w-100' style={{marginBottom:"-10px"}}>
          <div className='d-flex w-100 px-10'>
            <input value={promoCode} onChange={(e)=>setPromoCode(e.target.value)} placeholder='Enter promo code' style={{outline:"none"}} className='w-100 p-5 border-rounded' />
            <button onClick={()=>applyPromoHandler()} className='w-100 fw-600 border-rounded' style={{outline:"none", background:darkMode? 'white' : 'black', color:darkMode? 'black' : 'white'}}>Apply</button>
          </div>
        </div>
      }
      {data.map((item, index) => {
        return(
          <Card hidePurchaseButton={userInfo?.is_active} activeMembership={activeMembershipId === item.id} daysLeft={remainingDays}  key={`${index}-${item.title}`} title={item.title} desc={item.desc} price={discount ? item.price - (item.price * discount / 10) : item.price} index={index} selected={selected === index} setSelected={setSelected} _id={item.id} promoUsed={discount} promoText={promoCode}/>
        )
      })}
    </div>
  )
}

export default MembershipPage