import React, { useContext, useEffect, useState } from 'react';
import './calendar.styles.scss';
import { BackButton, UserImageCard } from '../../components';
import { getEventsDates, getFormattedDate } from '../../utils/service';
import { useNotify } from '../../components/Toast';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/stateContext';
import { BsInfoCircle } from 'react-icons/bs';

const CalendarPage = () => {
  const { userInfo, darkMode } = useContext(StateContext)
  const navigate = useNavigate()
  const [currentDate, setCurrentDate] = useState(new Date());
  const [datesData, setDatesData] = useState([])
  const [eventData, setEventData] = useState([])
  const [eventsText, setEventsText] = useState('')
  const [showInfoText, setShowInfoText] = useState(false)
  const {notify} = useNotify()

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getDateBasedText = () => {
    const today = new Date()
    if (currentDate.toDateString() === today.toDateString()) {
      setEventsText("Today's");
      return;
    }
  
    if (currentDate.getTime() > today.getTime()) {
      setEventsText("Upcoming");
      return;
    }
  
    if (currentDate.getTime() < today.getTime()) {
      setEventsText("Past");
      return;
    }
  }

  const renderDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = getDaysInMonth(year, month);
    const days = [];

    // Fill in the blanks before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="empty"></div>);
    }

    // Add the actual days
    for (let day = 1; day <= daysInMonth; day++) {
      const date = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const eventDates = datesData.filter(item => item.date === date);
      const event = eventDates.filter(item => item.status === true || item.status === null)
      const eventCancelled = eventDates.filter(item => item.status === false)
      days.push(
        <div
          onClick={event.length > 0 || eventCancelled.length > 0 ? ()=> setCurrentDate(new Date(year, month, day)) : ()=> true}
          key={day}
          className={`day ${event.length > 0 ? 'event' : ''}`}
        >
          <span>{day}</span>
          {eventCancelled?.length > 0 &&
            <span className='dot'></span>
          }
          {/* {event.length > 0 && <div className="event-text">requested for tour</div>} */}
        </div>
      );
    }
    return days;
  };

  useEffect(()=>{
    getDateBasedText(currentDate)
    // eslint-disable-next-line
  }, [currentDate])

  const FetchDatesDataBasedOnMonth = (month=new Date().getMonth()+1) =>{
    getEventsDates(month, new Date().getFullYear(), userInfo?.isGuide).then(res => {
      setDatesData(res.data)
      const todaysDate = getFormattedDate(new Date())
      const todaysEvent = res.data?.filter(item => item.date === todaysDate)
      setEventData(todaysEvent)
    }).catch(err =>{
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  const navigateMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + direction);
    newDate.setDate(new Date().getDate());
    setCurrentDate(newDate);
    if(direction < 0){
      FetchDatesDataBasedOnMonth(newDate.getMonth() + 1)
    }
    else{
      FetchDatesDataBasedOnMonth(newDate.getMonth() + direction)
    }
  };

  const navigateYear = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setFullYear(newDate.getFullYear() + direction);
    setCurrentDate(newDate);
  };

  useEffect(()=>{
      FetchDatesDataBasedOnMonth()
      //eslint-disable-next-line
  },[])

  useEffect(()=>{
    const todaysDate = getFormattedDate(currentDate)
    const todaysEvent = datesData?.filter(item => item.date === todaysDate)
    setEventData(todaysEvent)
    //eslint-disable-next-line
  }, [currentDate])

  const editHandler = (userId, currentId, isPersonalizedTour, image, fullName, userDetailsId, username) => {
    if(isPersonalizedTour){
      return navigate('/Personalized-Tour', {state : 
        { id:userId,
          userDetailsId,
          bookingId:currentId, 
          image, 
          fullName,
          username
        }})
    }
    return navigate('/Guide-details', {state : {
      id:userDetailsId,
      userId,
      bookingId:currentId}})
  }

  const handleTooltip = () => {
    setShowInfoText(true)
    setTimeout(()=>{
      setShowInfoText(false)
    }, 2000)
  }

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <div className='d-flex space-between items-center position-relative'>
        <BackButton text='Calendar'/>
        <BsInfoCircle className='dark-mode-text px-10' size={22} onClick={()=>handleTooltip()} />
        {showInfoText &&
          <p className='position-absolute text-whitesmoke dark-mode-text tooltip-calendar'>All scheduled, pending & cancelled bookings info will appear here.</p>
        }
      </div>
      <div className='d-flex content-center'>
          <div className="calendar">
          <div className="header">
              <button onClick={() => navigateYear(-1)}>&laquo;</button>
              <button onClick={() => navigateMonth(-1)}>&lsaquo;</button>
              <span>{months[currentDate.getMonth()]} {currentDate.getFullYear()}</span>
              <button onClick={() => navigateMonth(1)}>&rsaquo;</button>
              <button onClick={() => navigateYear(1)}>&raquo;</button>
          </div>
          <div className="days-of-week">
              {daysOfWeek.map((day) => (
                <div key={day} className="day-name">{day}</div>
              ))}
          </div>
          <div className="days">
              {renderDays()}
          </div>
          </div>
      </div>
      <div className='px-10'>
        <div className='p-10 bg-lightgray border-rounded' style={{marginTop:20}}>
        <p className='fw-600'>{eventsText} Events</p>
        {eventData.length === 0 && <p className='fs-14 text-center'>No Events.</p>}
        {eventData.map((item) => {
          return(
              <UserImageCard 
                key={item._id}
                id={item._id}
                bookingDate={item.date}
                isVerified={item.isVerified}
                editHandler={editHandler}
                status={item.status}
                image={userInfo?.isGuide ? item.profileImage : item.guide.profileImage}
                userId={item.guide.userId}
                userDetailsId={item.guide.guideUserDetailId}
                username={item.guide.username}
                fullName={userInfo?.isGuide ? item.user.fullName : item.guide.firstName}
                created={item.createdAt}
                isPersonalizedTour={item.isPersonalizedTour}
                booktext='Booked on' 
              />
          )
        })}
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
