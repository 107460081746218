import React, { useContext } from 'react'
import { StateContext } from '../../context/stateContext'

const CancellationRefundPolicy = () => {
    const {darkMode} = useContext(StateContext)
  return (
    <div className={`p-4 ${darkMode ? 'dark-mode': ''} `}>
        <div className='p-3'>
            <h1 className='dark-mode-gray-text fw-500 text-center'>Cancellation & Refund Policy</h1>
            <h2 className='dark-mode-gray-text fw-500'>Booking Cancellation & Refund Policy</h2>
            <p className='dark-mode-gray-text'>You can cancel your booking for a full refund up to 1 day (24 hours) before your activity. However, please check the ‘Terms and Conditions’ section of your ticket for the detailed cancellation policy for your specific activity.</p>
            <p className='dark-mode-gray-text'>For refundable tour / activity the turnaround time to process the refund and credit the amount to the customer’s bank account are 4-5 working days.</p>
            <p className='dark-mode-gray-text'>NOTE:- The 'Cancel Booking' option will not be available if your tour/activity is designated as 'Non-Refundable' as these reservations cannot be cancelled or changed.</p>
            <br/>
            <h2 className='dark-mode-gray-text fw-500'>Money and Refund</h2>
            <p className='dark-mode-gray-text'>Travel plans can sometimes be unpredictable, and we get that. When you book and pay via City2Guide, you benefit from our straightforward refund policy. In case you need to cancel, our policy is designed to be clear and fair, offering you a level of financial protection. This approach gives you the flexibility to make bookings with the assurance that you have a safety net in unforeseen circumstances. Additionally, there is no convenience / processing fee, no hidden charges & no commission of monetary transaction between the tourist (guest) and host (guide).</p><br/>

            <h2 className='dark-mode-gray-text fw-500'>Policy and Compliance</h2>
            <p className='dark-mode-gray-text'>Following our booking and payment policies is essential for a smooth City2Guide experience. These policies are in line with international travel standards and local laws, ensuring your tours are not only enjoyable but also adhere to ethical and legal guidelines. By booking through City2Guide, you contribute to sustainable tourism practices that positively impact local communities and the environment.</p><br/>

            <h2 className='dark-mode-gray-text fw-500'>Shipping Policy</h2>
            <p className='dark-mode-gray-text'>As City2Guide platform is an offline service-based online website to help traveller to find their local guide in the city. So, once you had made a payment for particular membership/subscription service, it will instantly be applied to your account when the transaction is successful to use the website effectively and seamlessly.</p><br/>
            <p className='dark-mode-gray-text'>We City2Guide do not charge any fee amount for cancellation on tour.</p><br/>
            <p className='dark-mode-gray-text'>Our refund policy was made to be transparent so your experience is as smooth as possible and gives you a fair go. In such cases we do not charge cancellation amount are as follows:</p><br/>
            <p className='dark-mode-gray-text'>- Reducing the number of guests on your booking.</p><br/>
            <p className='dark-mode-gray-text'>- Rescheduled bookings</p><br/>
            <p className='dark-mode-gray-text'>- Same day bookings</p><br/>
            <p className='dark-mode-gray-text'>- Cancelling an unconfirmed booking</p><br/>
            <p className='dark-mode-gray-text'>But exceptional on cancelling a confirmed booking: If a guest (tourist) wish to cancel booking within 24 hours after the booking is accepted/confirmed by host (guide). Henceforth, the tourist had already paid 10% charges of tour in-advance, then the refund will not be initiated to tourist. The guest may consider the charged amount as service availability fee of guide.</p><br/>
            <p className='dark-mode-gray-text'>For any Questions, Requests or Complaints</p><br/>
            <p className='dark-mode-gray-text'>If you have any question or complaints about the processing of your personal data, please send an e-mail to <a href='mailto:support@city2guide.com' className='text-primary'>support@city2guide.com</a> or call on +91-8591214121. </p><br/>
        </div>

    </div>
  )
}

export default CancellationRefundPolicy