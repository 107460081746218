import React from 'react'
import './profilemessage.styles.scss'
import { url } from '../../config'

const ProfileMessage = ({message, time, media, self=true}) => {
  
  return (
    <>
      <div className={`profile-message-container ${!self ? 'flex-end' : ''}`}>
          {/* image */}
          {/* {self &&
              <img src={image} alt='' />
          } */}
          {/* message */}
          {message && !message?.includes("/media/chat_media/") &&
            <div className={`message-container-pm mx-7 ${!self ? 'not-self'  : ''}`}>
                <p dangerouslySetInnerHTML={{ __html: message?.replace(/\n/g, '<br />') }} />
                {time &&
                <>
                    <p className='timestamp'>
                      {time}
                    </p>
                </>
                }
            </div>
          }
          {/* {!self &&
              <img src={image} alt='' />
          } */}
      </div>
      {media &&
      <>
        {media?.map((item, index)=>{
            return(
              <div key={index} className={`d-flex ${!self ? 'flex-end' : ''} px-10`}>
                <div className={`message-images-container position-relative`}>
                  <img style={{marginBottom:15}} onClick={()=>window.open(url + item)} src={url + item} alt='' />
                    {time &&
                      <>
                          <p className='timestamp' style={{fontSize:10, position:"absolute", right:0, borderRadius:10, padding:2, background:'lightgray', bottom:5}}>
                            {time}
                          </p>
                      </>
                    }

                </div>
              </div>
            )
          })}
      </>
      }
    </>
  )
}

export default ProfileMessage