import React, { useContext } from 'react'
import { BackButton } from '../../components'
import { StateContext } from '../../context/stateContext'

const ListRenderer = ({text, number}) => {
    number = number.split(',').map((item, index) => {
        return(
            <p key={index}>{item}</p>
        )
    })
    return(
        <div className=' my-10 d-flex space-evenly'>
            <div className='d-flex space-between items-center w-85 border border-rounded p-3'>
                <p className='dark-mode-gray-text w-50'>{text}</p>
                <p className='text-end dark-mode-text w-50'>{number}</p>
            </div>
        </div>
    )
}

const EmergencyPage = () => {
    const {darkMode} = useContext(StateContext)
  return (
    <div className={`${darkMode ? 'dark-mode': ''}`}>
        <BackButton size={24}/>
        
        <h1 className='text-center fw-600 my-20 dark-mode-text'>Emergency Helpline</h1>

        {/* <div className='d-flex content-center'>
            <div className='bg-lightgray border-rounded d-flex items-center p-20  my-20 w-80'>
                <PiPhoneCallFill size={22}/>
                <p className='px-10'>Customer Care</p>
            </div>
        </div> */}
        <div>
            <ListRenderer text='Police' number='100'/>
            <ListRenderer text='Fire' number='101'/>
            <ListRenderer text='Ambulance' number='102 & 108'/>
            <ListRenderer text='Disaster Management' number='108'/>
            <ListRenderer text='Medical Helpline' number='104'/>
            <ListRenderer text='National Emergency' number='112'/>
            <ListRenderer text='Women Domestic Abuse' number='181'/>
            <ListRenderer text='Crime Stopper' number='1090'/>
            <ListRenderer text='Children in help' number='1098'/>
            <ListRenderer text='Cyber Crime' number='1930 & 155620'/>
            <ListRenderer text='Women Helpline' number='1091'/>
            <ListRenderer text='Road Accident' number='1073 & 1033'/>
            <ListRenderer text='Rescue & Relief ' number='1070'/>
            <ListRenderer text='Senior Citizen Helpline ' number='14567'/>
            <ListRenderer text='Indian Railway Security ' number='1322'/>
            <ListRenderer text='Human Rights ' number='1123385368 , +91-9810298900'/>
            <ListRenderer text='Tourist Helpline ' number='1363 , 1800111363'/>
            <ListRenderer text='Stress, Suicide & Others ' number='1800 233 3330, +91-9820466726, +91-9999666555,  +91-8376804102'/>
        </div>
        {/* <div className='position-absolute w-100' style={{bottom:'20px'}}>
            {showMessage &&
                <p className='text-center my-10 dark-mode-text'>Email & SMS Sent Successfully</p>
            }
            <ButtonComponent title='Done' wrapperContainerStyle={{margin:"0px 20px"}}/>
        </div>  */}
    </div>
  )
}

export default EmergencyPage