import React, { useContext, useEffect, useRef, useState } from 'react'
import './guidehomepage.styles.scss'
import { useNavigate } from 'react-router-dom'
import { BsCalendar2Check, BsChatDots, BsNintendoSwitch } from 'react-icons/bs'
import { StateContext } from '../../context/stateContext'
import { PiBell, PiMagnifyingGlass, PiMoon, PiSun } from 'react-icons/pi'
import { LineText, Loader, SideDrawer, UserImageCard } from '../../components'
import { useNotify } from '../../components/Toast'
import { createNotification, GeneralSocket, getEventsDates, getProfiles, updateNotificationMetaInformation, updateTourAcceptanceStatus, verifyOtp } from '../../utils/service'
import constants from '../../constants'
import Footer from '../Footer'


const GuideHomePage = () => {
  const [showSideDrawer, setShowSideDrawer] = useState(false)
  const [selectedTab, setSeletedTab] = useState('All')
  const [initialLoading, setInitialLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const {darkMode, setDarkMode, userInfo, notificationIds, showNewMessageBadge, setShowNewMessageBadge, showNotificationBadge, setShowNotificationBadge, showNewBookingBadge, setShowNewBookingBadge } = useContext(StateContext)
  const [activeModalId, setActiveModalId] = useState(null); // Track the active modal
  const {notify} = useNotify()
  const [username, setUsername] = useState('')
  const [profileSearchData, setProfileSearchData] = useState([])
  const [shake, setShake] = useState(true)
  const [timerId, setTimerId] = useState(null)
  const [data, setData] = useState([])
  const [tabBasedData, setTabBasedData] = useState([])
  const [showOtpModal, setShowOtpModal] = useState(false)
  const [clearOtpNotifier, setClearOtpNotifier] = useState(false)
  const [currentId, setCurrentId] = useState(null); // New state to store the current ID
  const [currentUserId, setCurrentUserId] = useState(null); // New state to store the current ID
  const [currentUserName, setCurrentUserName] = useState(null); // New state to store the current ID
  const [greeting, setGreeting] = useState('')
  const navigate = useNavigate()
  const generalSocketRef = useRef()

  
  const verifyOtpHandler = (otps) => {
    verifyOtp(currentId, otps).then(res => {
      notify('Otp verified')
      notify('Congratulations! You have verified the tour !')
      setShowOtpModal(false)
      setClearOtpNotifier(true)           
      setTimeout(()=>{
        setClearOtpNotifier(false)
      }, 2000)
      GeneralSocket(res.data.toUserId, userInfo?.username, generalSocketRef, 'Congratulations! your tour has been verified !', constants.bookingAndNotification)
      fetchDataHandler()
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      if(err.response && err.response.data.detail && err.response.data.detail.includes('accept')){
        setTimeout(()=>{
          navigate('/NotificationPage')
        }, 2000)
      }
    })
  }

  const getGreetingMessage = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0-23)
  
    if (currentHour >= 5 && currentHour <= 11) {
      return "Good Morning!";
    } else if (currentHour >= 12 && currentHour <= 16) {
      return "Good Afternoon!";
    } else if (currentHour >= 16 && currentHour <= 20) {
      return "Good Evening!";
    } else {
      // Randomly select between the two messages
      const messages = ["Welcome back!", "Glad to see you!"];
      return messages[Math.floor(Math.random() * messages.length)];
    }
  };
  

  const cancelHandler = (reasonText) =>{
    if(reasonText.length < 10){
      return notify('please enter a valid reason')
    }
    updateTourAcceptanceStatus(currentId, 'cancel', reasonText).then(res => {
      notify('Status updated')

      // Todo all id and username how do we pass it come up with that logic
			GeneralSocket(currentUserId, currentUserName, generalSocketRef, 'Your tour has been rejected by the guide.', constants.bookingAndNotification)
      fetchDataHandler()
    }).catch(err =>{
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      setShowOtpModal(false)
    })
  }

  const fetchDataHandler = () => {
    const now = new Date()
      setInitialLoading(true)
      // getTodaysEvent(now.getDate(), now.getMonth()+1, now.getFullYear()).then(res => {
      const isGuide = true
      getEventsDates(now.getMonth()+1, now.getFullYear(), isGuide).then(res => {
        setData(res.data)
        setTabBasedData(res.data)
      }).catch(err => {
        alert('error')
      }).finally(() => {
        setInitialLoading(false)
      })
  }

  useEffect(()=>{
      setGreeting(getGreetingMessage())
      fetchDataHandler()

      // shake animation related code
      const timer = setTimeout(()=>{
        setShake(false)
      }, 10000)

      return() => {
        clearTimeout(timer)
      }
      // eslint-disable-next-line
  },[])

  
  useEffect(()=>{
    if(username.length < 3){
      setProfileSearchData([])
      setLoading(false)
      return
    }
    if(timerId){
      clearTimeout(timerId)
    }
    setLoading(true)
    const id = setTimeout(()=>{
      getProfiles(username).then(res => {
        setProfileSearchData(res.data)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      }).finally(()=>{
        setLoading(false)
      })
    }, 2000)
    setTimerId(id)
    // eslint-disable-next-line
  }, [username])

  // gets the profile details of the user on which you clicked/tapped came from the search results.
  const searchTapHandler = (toUser, userDetailsId, isGuide, userId) => {
    if(!toUser || !userDetailsId){
      return notify('Something went wrong while navigating to profile page.')
    }
    createNotification(toUser, userInfo?.fullName + ' viewed your profile')
    if(isGuide){
      return navigate('/Guide-Details', {state:{id:userDetailsId, userId:userDetailsId, isProfileView:true}})
    }
    navigate('/Preview-Profile-Page', {state:{userDetailsId, userId}})
  }

  const tabClickHandler = (tabName) => {
    setSeletedTab(tabName)
    if(tabName==='All'){
      return setTabBasedData(data)
    }
    if (tabName === 'Upcoming') {
      const today = new Date(); // Get the current date
      const bookingFuture = data.filter(item => new Date(item.date) > today && item.status !== false);
      return setTabBasedData(bookingFuture);
  }
    if(tabName==='Completed'){
      const completed = data.filter(item => item.status === true && item.isVerified === true)
      return setTabBasedData(completed)
    }
    if(tabName==='Cancel'){
      const completed = data.filter(item => item.status === false)
      return setTabBasedData(completed)
    }
  }

  const openOtpModal = (id, userId, username) => {
    setCurrentId(id); // Set the selected ID when opening the modal
    setActiveModalId(id);
    setCurrentUserId(userId); // Set the selected user
    setCurrentUserName(username); // set the selected username
    
    setShowOtpModal(true);
  };

  const closeOtpModal = () => {
    setActiveModalId(null); // Reset the active modal ID
    setShowOtpModal(false);
  };

  const darkModeToggle = () => {
    setDarkMode(!darkMode)
    // 0 means light mode and 1 means dark mode
    const currentTheme = localStorage.getItem('themeCg')
    if(currentTheme){
      if(currentTheme === '0'){
        setDarkMode(true)
        localStorage.setItem('themeCg', '1')
      }
      else{
        setDarkMode(false)
        localStorage.setItem('themeCg', '0')
      }
    }
    else{
      // by default on page we have dark mode so toggle will be for light
      setDarkMode(false)
      localStorage.setItem('themeCg', '0')
    }
  }


  const badgeStatusToggle = (badgeName) => {
    if(badgeName.includes('booking')){
      setShowNewBookingBadge(false)
      const record = notificationIds.find(item => item.eventType === "booking")
      if(record){
        updateNotificationMetaInformation(record.id).catch(err=>{})
      }
      navigate('/Calendar')
    }
    if(badgeName.includes('notification')){
      setShowNotificationBadge(false)
      const record = notificationIds.find(item => item.eventType === "notification")
      if(record){
        updateNotificationMetaInformation(record.id).catch(err=>{})
      }
      navigate('/NotificationPage')
    }
    if(badgeName.includes('message')){
      setShowNewMessageBadge(false)
      const record = notificationIds.find(item => item.eventType === "message")
      if(record){
        updateNotificationMetaInformation(record.id).catch(err=>{})
      }
      navigate('/ChatHome')
    }
   

  }

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <div className='px-10 my-10'>
          {/* SideDrawer Import */}
          <SideDrawer isOpen={showSideDrawer} setIsOpen={setShowSideDrawer}/>  
          <div className='header-container'>
            <div className='home-quick-actions space-between' style={{marginBottom:12}}>
                <div className='d-flex items-center'>
                  {/* Dark mode, Greetings , Icons, Drawer */}
                  <div className='quick-actions-container login-page-container container'  onClick={()=>{badgeStatusToggle('booking')}}>
                    <BsCalendar2Check size={26} color={`${darkMode ? 'gray' : 'var(--black)'}`} />
                      {showNewBookingBadge &&
                        <div className='badge'>
                          <span>*</span>
                        </div>
                      }
                  </div>
                  <div className='quick-actions-container login-page-container container'  onClick={()=>{badgeStatusToggle('message')}}>
                      <BsChatDots size={27} color={`${darkMode ? 'gray' : 'var(--black)'}`} />
                      {showNewMessageBadge &&
                        <div className='badge'>
                          <span>*</span>
                        </div>
                      }
                  </div>
                  <div className='quick-actions-container login-page-container container'  onClick={()=>{badgeStatusToggle('notification')}}>
                      <PiBell size={30} color={`${darkMode ? 'gray' : 'var(--black)'}`} />
                      {showNotificationBadge &&
                        <div className='badge' style={{right:7}}>
                          <span>*</span>
                        </div>
                      }
                  </div>
                  <div className='quick-actions-container login-page-container container'>
                        {darkMode ?
                            <PiSun size={30} onClick={()=>darkModeToggle()} color={`${darkMode ? 'gray' : 'var(--black)'}`}/>
                            :
                            <PiMoon size={30} onClick={()=>darkModeToggle()} color={`${darkMode ? 'gray' : 'var(--black)'}`}/>
                        }
                  </div>
                </div>
                <div className='quick-actions-container login-page-container container' onClick={()=>setShowSideDrawer((prevState) => !prevState)}>
                  <BsNintendoSwitch size={24} color={`${darkMode ? 'gray' : 'var(--black)'}`}/>
                  {showNotificationBadge &&
                      <div className='badge' style={{right:7}}>
                        <span>*</span>
                      </div>
                    }
                </div>
            </div>
            <div className='my-4'>
              <h1 className='dark-mode-text'>Hi, {userInfo.fullName?.split(' ')[0]} <span className={`${shake ? 'rotate' : ''}`} style={{fontSize:30}}>👋</span></h1>
              <p className='dark-mode-text'>{greeting}</p>
            </div>
            <div className='d-flex items-center border p-5 ghp-input-container'>
              <input value={username} onChange={e => setUsername(e.target.value)} className='w-100 border-none' placeholder='Search Profile via Username'/>
              <PiMagnifyingGlass size={20}/>
            </div>
          </div>
          {/* CHIPS */}
          <div className='px-10 d-flex my-20 chips-container'>
              <p onClick={()=>tabClickHandler('All')} className={`cursor-pointer ${selectedTab === 'All' ? 'active' : ""}`}>All</p>
              <p onClick={()=>tabClickHandler('Upcoming')} className={`cursor-pointer ${selectedTab === 'Upcoming' ? 'active' : ""}`}>Upcoming</p>
              <p onClick={()=>tabClickHandler('Completed')} className={`cursor-pointer ${selectedTab === 'Completed' ? 'active' : ""}`}>Completed</p>
              <p onClick={()=>tabClickHandler('Cancel')} className={`cursor-pointer ${selectedTab === 'Cancel' ? 'active' : ""}`}>Cancel</p>
          </div>
          {/* Container */}
            {tabBasedData.length === 0 && !initialLoading ?
              <p className='text-center dark-mode-text'>No Data Found.</p>
              :
              <>
                {profileSearchData?.length <= 0 &&
                  <div className='p-10 bg-lightgray border-rounded'>
                    {tabBasedData.map((item) => {
                      return(
                        <UserImageCard
                          key={item._id}
                          id={item._id}
                          bookingDate={item.date}
                          // created={new Date(item.createdAt)}
                          image={item.profileImage}
                          fullName={item.user.fullName}
                          showModal={showOtpModal}
                          setShowModal={setShowOtpModal}
                          status={item.status}
                          activeModalId={activeModalId}
                          isVerified={item.isVerified} // true means the tour has been verified [ user has shared the otp with guide and guide has successfully verified the otp]
                          clearOtp={clearOtpNotifier}
                          handleOtpSend={verifyOtpHandler}
                          onOpenModal={() => openOtpModal(item._id, item.user.userId, item.user.username)} // Pass the id of the item to the modal
                          onCloseModal={closeOtpModal}
                          cancelHandler={cancelHandler}
                        />
                      )
                    })}
                  </div>
                }
              </>
            }
          {loading &&
            <Loader marginTop='10%'/>
          }
          {!loading && profileSearchData.length === 0 && username.length > 2 &&
            <p className='text-center text-primary my-5 p-5 fw-500 fs016'>No results found for "{username}"</p>
          }
          {profileSearchData.length > 0 && !loading &&
            <div className='p-10 border-rounded' style={{marginTop:-20}}>
              <LineText text={`From Search Results "${username}"`} />
              {profileSearchData.map((item, index)=>{
                return(
                  <UserImageCard 
                    hideStatus={true}
                    onClick={()=>searchTapHandler(item.username, item.userDetailsId, item.isGuide, item.id)}
                    key={index}
                    activeModalId='x' // to hide the modal
                    showModal={false}
                    image={item?.profileImage}
                    fullName={item?.fullName}
                    email={'@'+item?.username}
                    statusText={'View Profile'}
                  />
                )
              })}
            </div>
          }
      </div>
      <Footer />
    </div>
  )
}

export default GuideHomePage