import React, { useState } from 'react'
import './accordion.styles.scss'
import { url } from '../../config';

const Accordion = ({ items=[] }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div className="accordion-title" onClick={() => handleToggle(index)}>
            <div className="title dark-mode-text w-max-content">Day {item.day}</div>
            <div className="day dark-mode-text">{item.title}</div>
            <div className={`icon ${activeIndex === index ? 'active' : 'in-active'}`}>▲</div>
            {/* <div className={`icon ${activeIndex === index ? '' : ''}`}>{activeIndex === index ? '▲' : '▼'}</div> */}
          </div>
          {activeIndex === index && (
            <div className="accordion-content" style={{          
              background: `url(${url}${item.image}) no-repeat center`, width:"100%", backgroundSize:"cover"
            }}>
              <div className='ac-description-container'>
                <p>{item.description}</p>
              </div>
                
            </div>
          )}
        </div>
      ))}
    </div>
  );
};


export default Accordion