import React, { useContext, useState } from 'react'
import './all-component.styles.scss'
import { wizard1 } from '../../assets/images'
import { ButtonComponent , IconInput, OtpComponent} from '../../components'
import { CiMail } from "react-icons/ci";
import Wrapper from './Wrapper'
import PhoneInput from 'react-phone-input-2';
import { StateContext } from '../../context/stateContext';
import { ContactVerificationDesktop } from '../../assets/desktop-svgs';

const ContactVerification = () => {
  const {userForm, setUserForm} = useContext(StateContext)
  const [otpState, setOtpState] = useState({
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:''
  })
  const [emailState, setEmailState] = useState({
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:''
  })

  return (
    <Wrapper image={wizard1} desktopImage={ContactVerificationDesktop} slideIndex={1} title='Contact Verification' nextButtonText="Next" nextButtonUrl='/verification-wizard/personal-information'>

        {/* flag with input  */}
        <div className='w-100'>
            <PhoneInput
                containerClass='react-telephone-custom-class'
                country={'in'}
                value={userForm?.contact}
                onChange={phone => setUserForm({...userForm, contact:phone})}
            />
        </div>
        {/* button */}
        <ButtonComponent title='Get OTP' />
        
        {/* otp boxes */}
        <OtpComponent state={otpState} setState={setOtpState}/>

        {/* Dont receive the otp ? RESENT OTP : text */}
        <p className='resend-otp'>Don't receive the OTP ? <span>RESEND OTP</span></p>

        {/* hr */}
        <hr />

        {/* email icon with input */}
        <IconInput value={userForm?.email} onChange={(e)=>setUserForm({...userForm, email:e.target.value})} type='email' Icon={CiMail} iconSize={29} placeholder='Email'/>
        
        {/* Get Otp button */}
        <ButtonComponent wrapperContainerStyle={{marginTop:'20px'}} title='Get OTP' />

        {/* otp boxes */}
        <OtpComponent state={emailState} setState={setEmailState}/>


    </Wrapper>

  )
}

export default ContactVerification