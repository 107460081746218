import React, { useContext, useEffect, useState } from 'react'
import { BackButton } from '../../components'
import { blockUser, getBlockedUser } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { meditation } from '../../assets/images'
import { StateContext } from '../../context/stateContext'

const UnblockProfilePage = () => {
  const [data, setData] = useState([])
  const { darkMode } = useContext(StateContext)
  const { notify } = useNotify()
  
  const ProfileContentRender = ({userId, userImage, fullName, userName}) => {
    const unblockHandler = () => {
        blockUser(userId).then(res => {
          notify(`User ${fullName} is unblocked.`)
          getBlockedUser().then(res => {
            setData(res.data)
          }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
          })
        }).catch(err => {
          notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }
    return(
      <div className='my-20 px-10'>
        <div className='d-flex  space-between items-center border p-5 border-rounded'>
          <img className='border' style={{height:50, width:50, borderRadius:'50%'}} src={userImage} alt={userName} />
          <div className='d-flex column '>
            <p className='dark-mode-text'>{fullName}</p>
            <p style={{marginTop:-3, marginLeft:-2}} className='fw-400 text-lightgray fs-12 dark-mode-gray-text'>@{userName}</p>
          </div>
          <button onClick={()=>unblockHandler()} className='fs-14 cursor-pointer' style={{padding:'6px 14px', color:"white", borderRadius:5, border:'none', backgroundColor:"var(--gray)"}}>Unblock</button>
        </div>
      </div>
    )
  }
  
  useEffect(()=> {
    getBlockedUser().then(res => {
      setData(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  //eslint-disable-next-line
  },[])

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <BackButton size={26} text='Blocked Users' />
      <br />
      {data.length > 0 ? 
      <>
          {data.map((item, index) => (
            <ProfileContentRender key={index} userId={item.blockedUserId} userImage={item.profileImage} userName={item.username} fullName={item.fullName}/>
          ))}
      </>
      :
      <div className='d-flex content-center'>
        <div className='d-flex column items-center'>
          <img src={meditation} alt='no blocked' />
          <h2 className='fw-500 dark-mode-text'>No Blocked Users Yet.</h2>
        </div>
      </div>
      }

    </div>
  )
}

export default UnblockProfilePage