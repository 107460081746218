import React, { useContext, useEffect, useState } from 'react'
import { StateContext } from '../../context/stateContext'
import { useNavigate } from 'react-router-dom'
import { BackButton } from '../../components'


const LineBreak = () => {
    return(
        <>
            <br />
                <div className='d-flex space-evenly'>
                    <hr className='my-10 w-75' />
                </div>
            <br/>
        </>
    )
}

const AboutUs = () => {
    const {darkMode} = useContext(StateContext)
    const [isTourist, setIsTourist] = useState(true)
    const handleClick = (type) => {
        setIsTourist(type === 'tourist');
    };
    const navigate = useNavigate()
    useEffect(()=>{
        window.scroll(0, 0)
    },[])
    return (
        <div className={`${darkMode ? 'dark-mode': ''} container`}>
            <div className='px-10'>
                <BackButton />
                <p className='fw-400 dark-mode-text'>You are viewing for <span className='text-primary fw-600'>{isTourist ? 'Tourist ' : "Guide "}</span></p>
                    <div className="button-container">
                        <div className={`slider ${isTourist ? 'left' : 'right'}`}></div>
                        <button className="toggle-button" style={{color:`${isTourist ? 'white' :'black' }`}} onClick={() => handleClick('tourist')}><span>Tourist</span></button>
                        <button className="toggle-button" style={{color:`${!isTourist ? 'white' :'black' }`}} onClick={() => handleClick('guide')}>Guide</button>
                </div>
                {!isTourist ?
                    <div className='px-10 my-10'>
                        <h1 className='fw-500 dark-mode-gray-text'>City2Guide</h1>
                        <h3 className='fw-500 dark-mode-text'>About Us</h3>
                        <p className='fw-300 dark-mode-text text-primary'>Explore the Uncommon</p>
                        <br />
                        <hr />
                        <br />
                        <p className='fw-300 dark-mode-text'><b>City2Guide</b> is a worldwide platform that provides connection between tourist & local guide, 
                        which help them focus on travel experience and explore food, culture and people of the city 
                        like a local. C2G will change the way people travel by making it easy to find reliable, 
                        experienced and inspiring local guides absolutely everywhere in the India. The platform 
                        offers a range of features to ensure personalized, authentic and memorable adventures for 
                        every traveler across all interests. <br/>
                        Travelers can discover new cities, towns and regions through many lenses: History, 
                        Photography, Food & Wine, Architecture, Ecotourism, Music, Meditation, Experiential Tours, 
                        and many more...
                        </p>

                        <LineBreak />

                        <h1 className='fw-500 text-primary text-center'>BECOME A LOCAL CITY GUIDE</h1>
                        <p className='text-primary text-center' style={{fontStyle:"italic"}}>Earn by sharing your passion for your city with the travelers </p>

                        <p className='dark-mode-text fw-300'><b>✓ We make your dream a (paid) reality</b>
                        Become a City2Guide host (guide) and receive a fair payment per booking so you can make 
                        money by sharing your passion. </p><br/>
                        
                        <p className='dark-mode-text fw-300'><b>✓ We give you the right tools </b>
                        At City2Guide, you get all the tools you need to manage your hosting schedule in a way that 
                        works best for you </p><br/>
                        
                        <p className='dark-mode-text fw-300'><b>✓ We have the experiences people love</b>
                        With your expertise and passion, plus our seamless tech you are set for a good start as a 
                        local host. </p><br/>
                        
                        <p className='dark-mode-text fw-300'><b>✓ We help you take part in responsible tourism</b>
                        Bring benefits to your community and lead tours in a way that respects the environment 
                        and those around it. </p><br/>
                        
                        <p className='dark-mode-text fw-300'><b>✓ We want to make you shine!</b>
                        To increase your opportunities and boost your career as a City2Guide host, we will do all the 
                        marketing for you and promote our tours in major online travel agencies.</p><br/>

                        <LineBreak />

                        <h1 className='fw-500 text-primary text-center'>One platform to rule it all</h1>
                        <p className='text-primary text-center' style={{fontStyle:"italic"}}>Manage your earnings, schedule and receive bookings from the biggest tour providers-all on the City2Guide Host platform. </p>

                            <p className='dark-mode-text'><b>Be in charge of your own schedule </b>
                            Choose when you are available to host with an easy to use functions by managing booking 
                            requests. </p><br/>
                            <p className='dark-mode-text'><b>Grow your skills along the way </b>
                            Access learning content created together with our most experienced hosts (guide). </p><br/>
                            <p className='dark-mode-text'><b>Adopt responsible tourism practices </b>
                            Work alongside UN development goals towards a brighter future with people and planet in 
                            mind for sustainable tourism. </p><br/>
                            <p className='dark-mode-text'><b>More opportunities from partner program </b>
                            As a C2G host (guide), you’ll be promoted on & get bookings from major online travel 
                            agencies. </p><br/>
                            <p className='dark-mode-text'><b>A proactive community </b>
                            Be part of growing community of hosts from all over the India and connect over social 
                            media groups, virtual events or in person. </p><br/>
                            <p className='dark-mode-text'><b>Your brand and online presence </b>
                            You are in control of your guide profile and can edit whenever you like to reflect who you 
                            truly are! </p><br/>

                    

                        <div className='my-10 p-4 bg-primary border-rounded'>
                            <h3 className='text-center fw-400'>Start your journey with <span onClick={()=>navigate('/Signup')} className='fw-500 fs-18 bg-lightgray px-3 py-3 border-rounded'>City2Guide</span></h3>
                            <p className='dark-mode-text text-center fs-14'>Become a local guide part-time or full-time. </p>
                        </div>

                        <LineBreak />

                        <h1 className='fw-500 text-primary text-center'>Start hosting right away by joining our community!</h1>
                        <p className='text-primary text-center' style={{fontStyle:"italic"}}>Are you foodie, a history lover or an art avid ?</p>
                        <div className='px-10 my-10'>
                            <p className='dark-mode-text fs-14'>If you are passionate and an enthusiastic person with local knowledge and expertise. 
                            We welcome you to join and be ready to spice up with your expertise & unique personality.</p>
                        </div>

                        <div className='dark-mode-text px-10 fw-400'>
                            <b>✓ For hosts of all experience levels </b>
                            Whether you are an expert in the field or starting your career as a local guide; C2G platform 
                            are fit for beginners as well as professional guides! <br/><br/>
                            
                            <b>✓ Guaranteed bookings </b>
                            Our City2Guide services are proven tour concepts designed based on years of collecting 
                            traveler’s insights and proven records of guests’ satisfaction. <br/><br/>
                            
                            <b>✓ A tour category for every interest </b>
                            From food and history tours to day trips, there’s a tour that fits your interests and 
                            knowledge – pick the one(s) that fits you best!<br/>
                        </div>

                        <LineBreak />

                        <h1 className='text-primary fw-400 text-center'>Empowering Hosts Globally</h1>
                        <p className='text-primary text-center' style={{fontStyle:"italic"}}>Be part of a global community of passionate local guides</p>

                        <div className='dark-mode-text'>
                            As a C2G host, you will instantly become part of our community. Learn from other local 
                            guides from all over India, meet like-minded people, and get the support you need to keep 
                            improving in your career as a local guide & make Incredible India proud. 
                        </div>
                        <br />

                        <LineBreak />

                        <h1 className='text-primary fw-400 text-center'>We support Sustainable Tourism</h1>

                        <div className='dark-mode-text'>
                            City2Guide support tours & activities that are responsible socially & environmentally 
                            sustainable. We believe in providing an authentic experience that immerses travellers in 
                            the local culture of the city they are visiting by exploring a side of the city that you would 
                            have never experienced on your own.<br /> 
                            Our vision is to empower tourists to explore the city like a local, and to discover the 
                            hidden gems that make it unique. We strive to provide our user with personalized tours 
                            that cater to their interests, allowing them to have a memorable and meaningful 
                            experience.<br />
                            At City2Guide, we are dedicated to promote cultural exchange and understanding 
                            between travellers and locals.<br />
                        </div>
                        <br />
                        <br />
                        <p className='text-center fw-600 text-primary my-2'>We're on a mission to reshape the way we travel.</p>
                        <p className='text-center fw-600 text-primary'>As you know, it's never too late to make any day extraordinary in the Incredible India. </p>

                        <LineBreak />

                        <h1 className='text-primary fw-400 text-center'>Contact Us</h1>

                        <div className='dark-mode-text'>
                            Thank you for considering City2Guide for your city exploration needs. We are dedicated to 
                            providing you with the best possible experience and making your trip a memorable one. <br/><br/>
                            If you have any questions or concerns, please don’t hesitate to contact us at 
                            <a href='mailto:support@city2guide.com' className='text-primary'> support@city2guide.com</a> or give us a call on <a href='tel:+91 8591214121.' className='text-primary'>+91 8591214121.</a>
                        </div>
                        <br/>

                    </div>
                :
                    <div className='px-10 my-10'>
                        <h1 className='fw-500 dark-mode-gray-text'>City2Guide</h1>
                        <h3 className='fw-500 dark-mode-text'>About Us</h3>
                        <p className='fw-300 dark-mode-text'>Explore the Uncommon</p>
                        <br />
                        <hr />
                        <br />
                        <p className='fw-300 dark-mode-text'><b>City2Guide</b> is a worldwide platform that provides connection between tourist & local guide, 
                        which help them focus on travel experience and explore food, culture and people of the city 
                        like a local. C2G will change the way people travel by making it easy to find reliable, 
                        experienced and inspiring local guides absolutely everywhere in the India. The platform 
                        offers a range of features to ensure personalized, authentic and memorable adventures for 
                        every traveler across all interests. <br/>
                        Travelers can discover new cities, towns and regions through many lenses: History, 
                        Photography, Food & Wine, Architecture, Ecotourism, Music, Meditation, Experiential Tours, 
                        and many more...
                        </p>

                        <LineBreak />

                        <h1 className='fw-500 text-primary text-center'>Why Choose City2Guide? </h1>

                        <p className='dark-mode-text fw-300'><b>100% Customizable:</b> Let your local host tailor the tour completely to your wishes. </p>
                        <p className='dark-mode-text fw-300'><b>Private Guided Tours:</b> No strangers on your tour. It’s just you and your local city guide. </p>
                        <p className='dark-mode-text fw-300'><b>Responsible Tourism:</b> Our tours are designed with people, places & the planet in mind.</p>

                        <LineBreak />

                        <h1 className='fw-500 text-primary text-center'>Find Your Perfect Experience</h1>
                        <p className='text-primary text-center' style={{fontStyle:"italic"}}>Discover tours based on your interest </p>

                        <div className='dark-mode-text'>
                            <br/>
                            ✓ Amazing Walking Tour <br/>
                            ✓ Breathtaking Beaches & Parks Tour <br/>
                            ✓ Delicious Food Tour <br/>
                            ✓ Enchanting Night Tour <br/>
                            ✓ Ecotourism Nature & Wildlife Tour <br/>
                            ✓ Explore Hidden Gems of the City  <br/>
                            ✓ Leisure Vacay & Entertainment Tour <br/>
                            ✓ Gorgeous Day Trips <br/>
                            ✓ Cultural & Local Attraction Tour <br/>
                            ✓ Historical Heritage & Art Tour <br/>
                            ✓ Physical Activity & Adventurous Tour <br/>
                            ✓ Spiritual & Religious Pilgrimage Tour <br/>
                            ✓ Seasonal Sightseeing & Festive Tour <br/>
                        </div>

                        <div className='my-10 p-4 bg-primary border-rounded'>
                            <h3 className='text-center fw-400'>Start your journey with <span onClick={()=>navigate('/Signup')} className='fw-500 fs-18 bg-lightgray px-3 py-3 border-rounded'>City2Guide</span></h3>
                        </div>

                        <LineBreak />

                        <div className='dark-mode-text px-10 fw-400'>
                            <b>Private & Personalized </b>
                            <p>No strangers, just you. Fully customizable tour tailored to your preferences as you wish. </p>
                            <br/>
                            <b>Without the crowds </b>
                            <p>Avoid the touristy spots. Tour designed to take you away from the crowds toward authentic local 
                            places. </p>
                            <br/>
                            <b>With an Expert City Guide </b>
                            <p>Experience the luxury discover hidden gems, unique experiences and top attractions tour that leaves 
                            you wanting more. </p>
                            <br/>
                            <b>Comfortable & Hassel-free Travel </b>
                            <p>Overcome the language barrier, concern related to food, overcharge & fraudulences, ward off 
                            harassment & racism.</p>
                        </div>

                        <LineBreak />

                        <h1 className='text-primary fw-400 text-center'>We support Sustainable Tourism</h1>

                        <div className='dark-mode-text'>
                            City2Guide support tours & activities that are responsible socially & environmentally 
                            sustainable. We believe in providing an authentic experience that immerses travellers in 
                            the local culture of the city they are visiting by exploring a side of the city that you would 
                            have never experienced on your own.<br /> 
                            Our vision is to empower tourists to explore the city like a local, and to discover the 
                            hidden gems that make it unique. We strive to provide our user with personalized tours 
                            that cater to their interests, allowing them to have a memorable and meaningful 
                            experience.<br />
                            At City2Guide, we are dedicated to promote cultural exchange and understanding 
                            between travellers and locals.<br />
                        </div>
                        <br />
                        <br />
                        <p className='text-center fw-600 text-primary my-2'>We're on a mission to reshape the way we travel.</p>
                        <p className='text-center fw-600 text-primary'>As you know, it's never too late to make any day extraordinary in the Incredible India. </p>

                        <LineBreak />

                        <h1 className='text-primary fw-400 text-center'>Contact Us</h1>

                        <div className='dark-mode-text'>
                            Thank you for considering City2Guide for your city exploration needs. We are dedicated to 
                            providing you with the best possible experience and making your trip a memorable one. <br/><br/>
                            If you have any questions or concerns, please don’t hesitate to contact us at 
                            <a href='mailto:support@city2guide.com' className='text-primary'> support@city2guide.com</a> or give us a call on <a href='tel:+91 8591214121.' className='text-primary'>+91 8591214121.</a>
                        </div>
                        <br/>

                    </div>
                }
            </div>
            
        </div>
    )
}

export default AboutUs