import React from 'react'
import './reviewscontainer.styles.scss'
import { Rating } from '..'
import { PiUserCheck } from 'react-icons/pi'
import { formatDistance, subDays } from 'date-fns'

const ReviewsContainer = ({data, replies}) => {
  return (
    <div className='reviews-container'>
        {/* user image and information container */}
        <div className='d-flex items-center'>
            {/* image */}
            <img src={data.fromImage} alt={data.user} />
            {/* user name, rating, date */}
            <div className='d-flex column px-10'>
                {/* guide name and icon container */}
                <div className='d-flex'>
                    {/* guide name */}
                    <p className='guide-name dark-mode-gray-text'>{data?.fromUser?.fullName}</p>
                    {/* icon */}
                    <p><PiUserCheck size={20} color='var(--primary)'/></p>
                </div>
                <div className='d-flex ratings-container'>
                    <Rating rating={data?.overallRating}/>
                </div>
                <p className='date'>{formatDistance(subDays(data?.createdAt, 0), new Date(), { addSuffix: true })}</p>
              
            </div>
        </div>
        {/* feedback text from user */}
        <div className='d-flex flex-wrap items-center space-between'>
            <p className='desc-text dark-mode-gray-text'>
                {data?.review}
            </p>
            {data?.image && !data?.image.includes('null') &&
                <img onClick={()=>window.open(data?.image)} style={{margin:"10px 0px", height:40, border:'1px solid gray', width:40, borderRadius:0}} src={data?.image} alt='' />
            }
        </div>
        {replies?.length > 0 &&
            <div style={{marginLeft:10, borderLeft:'1px solid lightgray'}}>
                <div className='my-5'>
                    {replies?.map(item =>{
                        return(
                            <div className='d-flex items-center' style={{marginLeft:20, marginBottom:20}}>
                                <img style={{height:30, width:30, marginRight:10}} src={item.fromImage} alt={item.user} />
                                <p key={item.id} className='desc-text dark-mode-gray-text'>
                                    {item?.review}
                                </p>   
                            </div>
                        )
                    })}
                </div>
            </div>
        }
        <hr />
    </div>
  )
}

export default ReviewsContainer