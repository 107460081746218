import React, { useContext, useEffect, useState } from 'react'
import { Login, logo } from '../../assets/images'
import './loginpage.styles.scss'
import { ButtonComponent, IconInput } from '../../components'
import { IoAt, IoLockClosedOutline  } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { login } from '../../utils/service'
import { StateContext } from '../../context/stateContext'
import { useNotify } from '../../components/Toast'
import { Authentication } from '../../assets/desktop-svgs'
import { FooterPage } from '..'

const LoginPage = () => {
  const [username, setUsername] = useState('faijan')
  const [password, setPassword] = useState('Qwerty@1234')
  const [showPassword, setShowPassword] = useState(false)
  const { darkMode, userInfo, setUserInfo } = useContext(StateContext)
  const {notify} = useNotify()

  const navigate = useNavigate()
  const loginHandler = () => {
    if(username.length <= 3 || username.includes(' ')){
      return notify('please enter valid username')
    }

    if(password.length <= 3 || password.includes(' ')){
      return notify('please enter valid password')
    }

    login(username?.toLowerCase(), password).then((res) => {
      localStorage.setItem("auth-cg", JSON.stringify(res.data));
      setUserInfo(res.data)
      navigate('/Home')
    }).catch(error => {
        let message = error.response && error.response.data.detail ? error.response.data.detail : error.message
        notify(message, 'crimson')
    })
  }

  useEffect(()=>{
    if(userInfo){
      navigate('/Home')
    }
    // eslint-disable-next-line
  },[])

  return (
    <>
      <div className={`${darkMode ? 'dark-mode' : ''}`}>
        <img src={logo} alt='city2guide logo' className='position-absolute hide-on-mobile' style={{height:100, width:100, top:10, left:10}}/>
        <div className='login-page-container container'>
          <div className='responsive-image-container'>
            <img className='login-image' src={Login} alt='India Gate with bird and clouds' />
            <img className='auth-image' src={Authentication} alt='Authentication' />
          </div>
          <div className='responsive-form-container'>
            <div className='text-container'>
              <h1 className='lp-login-text'>Login</h1>
              <p className='lp-explore-text'>Start exploring fascination places around the world.</p>
            </div>
            <div className='inputs-container'>
              <IconInput style={{textTransform:"lowercase"}} value={username} onChange={(e)=>setUsername(e.target.value)} Icon={ IoAt } placeholder='Username'/>
              <IconInput value={password} onChange={(e)=>setPassword(e.target.value)} Icon={ IoLockClosedOutline } type={`${showPassword ? 'text' : 'password'}`} placeholder='Password' EndIcon={ showPassword ? BsEye : BsEyeSlash } endIconFunction={()=>setShowPassword(!showPassword)}/>
            </div>
            <p onClick={()=>navigate('/Forgot-Password')} className='forgot-password dark-mode-text'>Forgot Password ?</p>
            <ButtonComponent title='Login' onClick={()=>loginHandler()} wrapperContainerStyle={{margin:'0px 20px', justifyContent:"center"}} />
            {/* <DividerComponent text='OR'/>
            <ButtonComponent title='Sign Up with Google' img={Google} buttonTextColor='black'  wrapperContainerStyle={{margin:'0px 20px', backgroundImage:"linear-gradient(to right bottom, whitesmoke  41%, whitesmoke  100%)"}}/> */}
            <p className='account-text'>Don't have an account ? <span className='cursor-pointer' onClick={()=>navigate('/Signup')}>Create Account.</span></p>
          </div>
        </div>
      </div>
      <FooterPage />
    </>
  )
}

export default LoginPage

