import React, { useRef } from 'react'
import { PiCamera, PiImage } from 'react-icons/pi'

const FileUpload = ({value, isVerified, side='Front',  ...props}) => {
  const inputRef = useRef()
  return (
    <div className='d-flex space-between items-center my-20 border-rounded p-10 bg-primaryLight'>
        {inputRef?.current?.files[0] || value ?
          <img style={{height:30,width:30}} src={inputRef?.current?.files[0] ? URL.createObjectURL(inputRef?.current?.files[0]) : value} alt='' />
          :
          <PiImage onClick={!isVerified ? ()=>inputRef.current.click() : ()=>true} size={30} color='var(--primary)' />
        }
        <div>
            <p className='fs-14'>Upload {side.length < 10 ? side + ' side' : side}</p>
            <p className='fs-12 text-lightgray'>file should be only in jpg format.</p>
            <input ref={inputRef} type='file' hidden {...props}/>
        </div>
        <div className='d-flex items-center column' style={{backgroundColor:"var(--primary)", padding:5, borderRadius:'10%'}}>
            <PiCamera onClick={!isVerified ? ()=>inputRef.current.click() : ()=>true} size={24} color='white' />
        </div>
    </div>
  )
}

export default FileUpload