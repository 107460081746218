import React from 'react'
import { guide1, guide2, guide3, user1 } from '../../assets/images'
import './d-popularplacescard.styles.scss'

const index = ({image, title, desc, visitors, buttonText, onClick=()=>true}) => {
  return (
    <div className='Desktop-popular-place-card-container'>
        <img className='main-image' src={image} alt={title} />
        <h1 className='fw-500 fs-20'>{title}</h1>

        <p className='desc' style={{height:"100px", overflow:"hidden"}}>{desc}</p>

        <p style={{marginTop:10}} className='fs-14 fw-400 text-lightgray'>{visitors} + Visitors</p>
        <div className='d-flex space-between items-center'>
            <div className='position-relative d-flex items-center'>
                <img className='position-absolute border' style={{height:30, bottom:-20, objectFit:"cover", zIndex:1, width:30, borderRadius:"50%", left:-5}} src={user1} alt='' />
                <img className='position-absolute border' style={{height:30, bottom:-20, objectFit:"cover", zIndex:2, width:30, borderRadius:"50%", left:10}} src={guide1} alt='' />
                <img className='position-absolute border' style={{height:30, bottom:-20, objectFit:"cover", zIndex:3, width:30, borderRadius:"50%", left:25}} src={guide2} alt='' />
                <img className='position-absolute border' style={{height:30, bottom:-20, objectFit:"cover", zIndex:4, width:30, borderRadius:"50%", left:40}} src={guide3} alt='' />
            </div>
            <div onClick={()=>onClick()} className='button-class'>
                {buttonText}
            </div>
        </div>
    </div>
  )
}

export default index