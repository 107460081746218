import React from 'react'
import './buttoncomponent.styles.scss'
const ButtonComponent = ({title, onClick=()=>true, loading, wrapperContainerStyle, buttonTextColor, img, imageText, Icon, iconColor, iconSize, buttonId, ...props}) => {
  
  return (
    <>
        <div className="button button-floating" style={wrapperContainerStyle} id={buttonId} onClick={loading ? ()=> true : onClick}>
          {img &&
            <img style={{width:24, marginRight:10}} src={img} alt={imageText} />
          }
          {Icon &&
            <Icon size={iconSize || 24} color={iconColor || 'white'} />
          }
          <p className='button-text' style={{color:buttonTextColor ? buttonTextColor : "white"}} {...props}>{loading ? 'Loading...' : title}</p>
        </div>
    </>
  )
}

export default ButtonComponent