import React, { useContext, useState } from 'react'
import { bankDetailsVerification } from '../../assets/images'
import Wrapper from './Wrapper'
import { PiBank, PiBuilding, PiCardsThree, PiInfoBold, PiPen, PiUser } from 'react-icons/pi'
import { FileUpload, IconInput, IconInputDropDown } from '../../components'
import { StateContext } from '../../context/stateContext'
import { FaPaypal, FaUser } from 'react-icons/fa'

const BankDetailsVerification = () => {
    const [currentTab, setSelectedText] = useState('Enter Details')

    const {identityVerificationForm, setIdentityVerificationForm} = useContext(StateContext)

    const changeHandler = (key, text) => {
      if (text && typeof text === 'object' && text instanceof Blob) {
          setIdentityVerificationForm({...identityVerificationForm, [key]:text})
      }
      setIdentityVerificationForm({...identityVerificationForm, [key]:text})
    }
    return (
        <Wrapper image={bankDetailsVerification} slideIndex={1} lineLength={3} title='Enter Bank Details' nextButtonText="Save & Continue" nextButtonUrl='/Profile-Verification/additional-documents'>
            <div className='bg-lightyellow border-rounded p-20 d-flex space-between items-center'>
                <PiInfoBold size={80} color='var(--primary)' className='me-2'/>
                <p className='fs-12'>These details are important to receive 20% Advance Booking Amount from Travelers.
                    The amount will be stored in City2Guide wallet.
                    You can withdraw it only twise in a month
                    i.e. after every 15 days.</p>
            </div>

            <div className="button-container">
                <div className={`slider ${currentTab === 'Enter Details' ? 'left' : 'right'}`}></div>
                <button className="toggle-button" style={{fontSize:'14px', color:`${currentTab === 'Enter Details' ? 'white' :'black' }`}} onClick={() => setSelectedText('Enter Details')}><span>Enter Details</span></button>
                <button className="toggle-button" style={{fontSize:'14px', color:`${currentTab === 'Upload Passbook' ? 'white' :'black' }`}} onClick={() => setSelectedText('Upload Passbook')}>Upload Passbook</button>
            </div>

            {currentTab !== 'Upload Passbook' &&
                <div>
                    <IconInput value={identityVerificationForm.accountHolderName} onChange={(e)=>changeHandler('accountHolderName', e.target.value)} Icon={PiUser} placeholder="Account holder's Name" />
                    <IconInput value={identityVerificationForm.bankName} onChange={(e)=>changeHandler('bankName', e.target.value)} Icon={PiBank} placeholder="Bank Name" />
                    <IconInput value={identityVerificationForm.accountNumber} onChange={(e)=>changeHandler('accountNumber', e.target.value)} type='number' Icon={PiCardsThree} placeholder="Account Number" />
                    <IconInput value={identityVerificationForm.ifscCode} onChange={(e)=>changeHandler('ifscCode', e.target.value)} Icon={PiPen} placeholder="IFSC Code" />
                    <IconInput value={identityVerificationForm.branchName} onChange={(e)=>changeHandler('branchName', e.target.value)} Icon={PiBuilding} placeholder="Branch Name" />
                    <IconInput value={identityVerificationForm.upi} onChange={(e)=>changeHandler('upi', e.target.value)} Icon={FaPaypal} placeholder="UPI Id" />
                    <IconInputDropDown Icon={FaUser} value={identityVerificationForm?.accountType} onChange={(e)=>changeHandler('accountType', e.target.value)}  selectData={[
                        {id:1, value:'Current', text:"Current"},
                        {id:2, value:'Savings', text:"Savings"}
                        ]} 
                    />
                </div>
            }
            {currentTab === 'Upload Passbook' &&
                <div>
                    <div className='bg-lightyellow border-rounded p-5 d-flex space-evenly items-center'>
                        <div>
                            <p className='fs-16'>Below details should be clearly visible</p>
                            <ul className='fs-14 fw-500 my-20' style={{listStyle:"inside", listStyleType:"circle"}}>
                                <li>Account Holder Name</li>
                                <li>Bank Name</li>
                                <li>A/C No</li>
                                <li>IFSC code</li>
                                <li>Branch Name</li>
                            </ul>
                        </div>
                    </div>
                    <FileUpload onChange={(e)=>changeHandler('passbookPhoto', e.target.files[0])}/>
                </div>
            }
        
            <div className='bg-crimsonLight border-rounded p-20 d-flex space-between items-center'>
                <PiInfoBold size={80} color='crimson' className='me-2'/>
                <p className='fs-12 dark-mode-text'>
                    Uploading incorrect or morphed document is a serious offence of Forgenry under Indian Penal Code of IT Act, 2000
                    (IPC Section 66C & 66D).
                    It may lead to permanent ban from City2Guide Platform.
                </p>
            </div>


            <div className='d-flex flex-start items-center my-10'>
                <input className='me-2' type='checkbox' />
                <p className='fs-14 dark-mode-text'>I acknowledge all the information shared above are true and correct.</p>
            </div>

            <div className='d-flex flex-start items-center my-10'>
                <input className='me-2' type='checkbox' />
                <p className='fs-14 dark-mode-text'>I agree to obey the policy of city2guide.</p>
            </div>
        </Wrapper>
    )
}

export default BankDetailsVerification