import React, { useContext } from 'react'
import { BackButton, Toggle } from '../../components'
import './changetheme.styles.scss'
import { PiDresserThin,  PiFanThin } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { StateContext } from '../../context/stateContext'

const ChangeThemePage = () => {
    const navigate = useNavigate()
    const {darkMode} = useContext(StateContext)
    return (
        <div className={`${darkMode ? 'dark-mode' : ''}`}>
            <BackButton size={24} text='Change theme'/>

            <div className='icon-text-container d-flex space-between items-center'>
                <div className='d-flex items-center'>
                    <PiFanThin />
                    <p>Profile Theme</p>
                </div>
                <Toggle />
            </div>
                
            <div className='icon-text-container d-flex space-between items-center'>
                <div onClick={()=>navigate('/Messages-Theme')} className='d-flex items-center'>
                    <PiDresserThin />
                    <p>Message Theme</p>
                </div>
            </div>

                
        </div>
    )
}

export default ChangeThemePage