import React, { useContext, useState } from 'react';
import './toggle.styles.scss'; 
import { StateContext } from '../../context/stateContext';

const Toggle = () => {
  const { darkMode, setDarkMode } = useContext(StateContext)
  const [isToggled, setIsToggled] = useState(darkMode);


  const darkModeToggle = () => {
    setDarkMode(!darkMode)
    // 0 means light mode and 1 means dark mode
    const currentTheme = localStorage.getItem('themeCg')
    if(currentTheme){
      if(currentTheme === '0'){
        setDarkMode(true)
        localStorage.setItem('themeCg', '1')
      }
      else{
        setDarkMode(false)
        localStorage.setItem('themeCg', '0')
      }
    }
    else{
      // by default on page we have dark mode so toggle will be for light
      setDarkMode(false)
      localStorage.setItem('themeCg', '0')
    }

  }
  
  const handleToggle = () => {
    setIsToggled(!isToggled);
    darkModeToggle()
  };

  return (
    <div className="toggle-container" onClick={handleToggle}>
      <div className={`toggle ${isToggled ? 'toggled' : ''}`}>
        <div className="toggle-circle"></div>
      </div>
    </div>
  );
};

export default Toggle;
