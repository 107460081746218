import React, { useContext, useEffect, useRef, useState } from 'react'
import './updateusername.styles.scss'
import { StateContext } from '../../context/stateContext'
import { BackButton, ButtonComponent, IconInput } from '../../components'
import { checkUsernameAvailability, updateUsername } from '../../utils/service'
import { PiUser } from 'react-icons/pi'
import { useNotify } from '../../components/Toast'

const UpdateUsername = () => {
    const {darkMode, userInfo} = useContext(StateContext)
    const [username, setUsername] = useState('')
    const [usernameAvailable, setUsernameAvailable] = useState(null)
    const { notify } = useNotify()
    let timer = useRef();
    useEffect(()=>{
        if(username.length < 3){
            setUsernameAvailable(null)
            return
        }
        if(timer.current){
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(()=>{
            checkUsernameAvailability(username).then(res => {
                setUsernameAvailable(res.data.available)
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })
        },700)
        return(()=>{
            clearTimeout(timer.current)
        })
    //eslint-disable-next-line
    },[username])
    
    const updateUsernameHandler = () =>{
        if(username.length < 3){
            return notify("Please enter a valid username")
        }
        updateUsername(username).then(res => {
            notify('Username updated')
            userInfo['username'] = username
            
            let userLocalData = JSON.parse(localStorage.getItem('auth-cg'))
            userLocalData['username'] = username
            localStorage.setItem('auth-cg', JSON.stringify(userLocalData))
        }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.response.detail : err.message)
        })
    }

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>  
        <BackButton size={24} text='Update Username' />
        <div className='px-20'>
            <IconInput value={userInfo?.username} disabled />
            <IconInput Icon={PiUser} placeholder='New Username' value={username} onChange={(e)=>setUsername(e.target.value)}/>
            {usernameAvailable &&
                <label className='help-text' style={{color:'green'}} >Username is available.</label>
                }
            {usernameAvailable === false &&
                <label className='help-text' style={{color:'red'}} >Username is already been taken</label>
            }
            <ButtonComponent onClick={usernameAvailable ? ()=> updateUsernameHandler() : () => true} title='Update Username' wrapperContainerStyle={{margin:"10px 0px"}}/>
        </div>
    </div>
  )
}

export default UpdateUsername