const bookingAndNotification = 'booking&notification'
const bookingAndNotificationAndMessage = 'booking&notification&message'
const notification = 'notification'
const notificationAndMessage = 'notification&message'
const message = 'message'
const booking = 'booking'

const constants =  {
    booking,
    bookingAndNotification,
    bookingAndNotificationAndMessage,
    notification,
    notificationAndMessage,
    message
}

export default constants