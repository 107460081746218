import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4';


// Meta 
const options = {
  autoConfig: true, // set pixels auto config
  debug: false, // enable logs
}

ReactPixel.init('1374650146828836', null, options)
ReactPixel.pageView(); // Track page views

// Google
ReactGA.initialize('G-3LKDBTSVZ7');  // Replace with your Measurement ID
ReactGA.send('pageview'); // Track page views

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
