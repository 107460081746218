import React, { useContext, useEffect } from 'react'
import { IndiaGate , IndiaGateDark} from '../../assets/images'
import { PiMoon, PiSun } from 'react-icons/pi';
import './welcomepage.styles.scss'
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from '../../components';
import { StateContext } from '../../context/stateContext';

const WelcomePage = () => {
  const {darkMode, setDarkMode, isTourist, setIsTourist, userInfo} = useContext(StateContext)

  const handleClick = (type) => {
      setIsTourist(type === 'tourist');
  };
  
  const navigate = useNavigate()

  useEffect(()=>{
    if(userInfo){
      navigate('/Home')
    }
    // eslint-disable-next-line
  },[userInfo])


  return (
    <div className={`${darkMode ? 'dark-mode' : ''} background-image`}>
        <div className='hide-on-desktop'>
          <div className='image-container-wp'>
              <img src={darkMode ? IndiaGateDark : IndiaGate} alt="India Gate" />
          </div>
          <div className="content">
              <h1 className='dark-mode-text'>Start your journey <br/>with <span className="highlight">City2Guide</span></h1>
              <p className='dark-mode-gray-text'>Discover Breathtaking destinations, book guides & explore the Incredible India like never before.</p>
              <div className="button-container">
                  <div className={`slider ${isTourist ? 'left' : 'right'}`}></div>
                  <button className="toggle-button" style={{color:`${isTourist ? 'white' :'black' }`}} onClick={() => handleClick('tourist')}><span>Tourist</span></button>
                  <button className="toggle-button" style={{color:`${!isTourist ? 'white' :'black' }`}} onClick={() => handleClick('guide')}>Guide</button>
              </div>
              <ButtonComponent title='Get Started' onClick={()=>navigate("/Login")} />
          </div>
        </div>
        <div className='hide-on-mobile'>
          <div className={`${darkMode ? 'dark-mode': 'light-mode'} d-flex column content-center h-100`}>
              <div className='d-flex flex-end'>
                  {darkMode ?
                      <PiSun onClick={()=>setDarkMode(!darkMode)} color='gray'/>
                      :
                      <PiMoon onClick={()=>setDarkMode(!darkMode)} color='gray'/>
                  }
              </div>
              <div className='d-flex content-center items-center'>
                  <div className='desktop-wrapper-container d-flex content-center'>
                      <div onClick={()=>{handleClick('tourist');navigate("/Login")}} className='traveler h-100 w-100 d-flex column content-center items-center'>
                          <h1 className='text-whitesmoke fw-400 text-center'>I am a Traveler</h1>
                      </div>
                      <div onClick={()=>{handleClick('guide');navigate("/Login")}} className='guide h-100 w-100 d-flex column content-center items-center'>
                          <h1 className='fw-400 text-center'>I am a Guide</h1>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
  );
}

export default WelcomePage