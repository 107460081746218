import React from 'react'
import { PiStar, PiStarFill, PiStarHalfFill } from 'react-icons/pi'

const Ratings = ({rating=0, setRating=()=>true}) => {
  return (
    <div className='ratings-star-container'>
      {rating >= 1 ? <PiStarFill onClick={()=>setRating(0)} color='var(--primary)' /> :rating >= 0.5 ? <PiStarHalfFill onClick={()=>setRating(0.5)} color='var(--primary)' /> : <PiStar onClick={()=>setRating(1)}/>}
      {rating >= 2 ? <PiStarFill onClick={()=>setRating(1)} color='var(--primary)' /> :rating >= 1.5 ? <PiStarHalfFill onClick={()=>setRating(1.5)} color='var(--primary)' /> : <PiStar onClick={()=>setRating(2)}/>}
      {rating >= 3 ? <PiStarFill onClick={()=>setRating(2)} color='var(--primary)' /> :rating >= 2.5 ? <PiStarHalfFill onClick={()=>setRating(2.5)} color='var(--primary)' /> : <PiStar onClick={()=>setRating(3)}/>}
      {rating >= 4 ? <PiStarFill onClick={()=>setRating(3)} color='var(--primary)' /> :rating >= 3.5 ? <PiStarHalfFill onClick={()=>setRating(3.5)} color='var(--primary)' /> : <PiStar onClick={()=>setRating(4)}/>}
      {rating >= 5 ? <PiStarFill onClick={()=>setRating(4)} color='var(--primary)' /> :rating >= 4.5 ? <PiStarHalfFill onClick={()=>setRating(4.5)} color='var(--primary)' /> : <PiStar onClick={()=>setRating(5)}/>}

    </div>
  )
}

export default Ratings