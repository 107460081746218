import React, { useContext, useState } from 'react'
import { PiCurrencyInr, PiX } from 'react-icons/pi'
import { ButtonComponent, ChipComponent } from '../../components'
import './filtercontent.styles.scss'
import 'react-range-slider-input/dist/style.css';
import RangeSlider from 'react-range-slider-input';
import { StateContext } from '../../context/stateContext';
import { advancedSearch } from '../../utils/service';
import { useNotify } from '../../components/Toast';

const TIME_CHIPS = ['3h', '6h', '12h']
const LANG_CHIPS = ['Hindi','Marathi','Telugu','English','Tamil','Kannada','Malayalam']
const AVAILABILITY_CHIPS = ['Active','Instantly Confirm', 'Virtual Tour']
const GENDER_PREFERENCE_CHIPS = ['Male','Female', 'Other']
const EXPERTISE_CHIPS = ['History', 'Guide', 'Guides', 'more+']

const TitleAndChips = ({title, setState, CHIPS=[], state=[]}) => {
    const clickHandler = (item) => {
        if (state.includes(item)){
            const results = state.filter(title => title !== item)
            setState(results)
            return
        }
        setState([...state, item])
    }
    return(
        <div className='title-chips-container'>
            <h4 className='subtitle-text dark-mode-text'>{title}</h4>
            <div className='d-flex space-evenly flex-wrap'>
                {CHIPS.map((item, index) => {
                    return (
                        <ChipComponent onClick={()=>clickHandler(item)} style={{transition:"all 0.2s ease-in-out", border:'0.5px solid var(--primary)', color:`${state.includes(item) ? 'white'  : 'black' }`, backgroundColor:`${state.includes(item) ? 'orange' : "white"}`, padding:`10px ${item.length > 10 ? '15px'  : '30px'}`}} text={item} key={`${item}-${index}`}/>
                    )
                })}
            </div>
            <hr />
        </div>
    )
}

const FilterContent = ({onClick, setData}) => {
    const { darkMode } = useContext(StateContext)
    const { notify } = useNotify()
    const [selectedTimeChips, setSelectedTimeChips] = useState([])
    const [selectedLangChips, setSelectedLangChips] = useState([])
    const [selectedAvailableChips, setSelectedAvailableChips] = useState([])
    const [selectedExpertiseChips, setSelectedExpertiseChips] = useState([])
    const [selectedGenderChips, setSelectedGenderChips] = useState([])
    const [selectedInterestChips, setSelectedInterestChips] = useState([])
    const [selectedProfessionChips, setSelectedProfessionChips] = useState([])

    const resetHandler = () => {
        setSelectedTimeChips([])
        setSelectedLangChips([])
        setSelectedAvailableChips([])
        setSelectedExpertiseChips([])
        setSelectedGenderChips([])
        setSelectedInterestChips([])
        setSelectedProfessionChips([])
    }

    const applyAdvancedFilter = () => {
        const query = {
            time:selectedTimeChips,
            lang:selectedLangChips,
            available:selectedAvailableChips,
            expertise:selectedExpertiseChips,
            gender:selectedGenderChips,
            interest:selectedInterestChips,
            profession:selectedProfessionChips
        }
        advancedSearch(query).then(res => {
            setData(res.data)
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
        <div className='filter-container'>
            {/* Filter : text  and close icon*/}
            <div className='my-20 d-flex space-between items-center'>
                <h1 className='title-text dark-mode-gray-text'>Filter</h1>
                <PiX onClick={onClick} className='close-icon'/>
            </div>

            {/* Price Range text & range */}
            <div className='range-container d-flex items-center space-between'>
                <h4 className='price-range-text dark-mode-gray-text'>Price Range</h4>
                <div className='d-flex items-center'>
                    <PiCurrencyInr className='dark-mode-gray-text'/>
                    <input />
                    <span className='dark-mode-text'>-</span>
                    <input />
                </div>
            </div>

            {/* Filter Range : check library */}
            <RangeSlider className='range dark-mode-text' min={10} max={100}/>
            <br/>
            <hr />

            <TitleAndChips title='Time' CHIPS={TIME_CHIPS} state={selectedTimeChips} setState={setSelectedTimeChips} />
            <TitleAndChips title='Language' CHIPS={LANG_CHIPS} state={selectedLangChips} setState={setSelectedLangChips}/>
            <TitleAndChips title='Availability' CHIPS={AVAILABILITY_CHIPS} state={selectedAvailableChips} setState={setSelectedAvailableChips}/>
            <TitleAndChips title='Gender' CHIPS={GENDER_PREFERENCE_CHIPS} state={selectedGenderChips} setState={setSelectedGenderChips}/>
            <TitleAndChips title='Area Of Expertise' CHIPS={EXPERTISE_CHIPS} state={selectedExpertiseChips} setState={setSelectedExpertiseChips}/>
            <TitleAndChips title='Interest' CHIPS={EXPERTISE_CHIPS} state={selectedInterestChips} setState={setSelectedInterestChips}/>
            <TitleAndChips title='Profession' CHIPS={EXPERTISE_CHIPS} state={selectedProfessionChips} setState={setSelectedProfessionChips}/>
            <br/>
            <div className='d-flex items-center space-between'>
                <ButtonComponent onClick={()=>resetHandler()} title='Reset' buttonTextColor='black' wrapperContainerStyle={{backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)", width:"45%", marginRight:10}}/>
                <ButtonComponent onClick={()=>applyAdvancedFilter()} title='Apply' wrapperContainerStyle={{width:"45%"}}/>
            </div>
            <br/>
        </div>
    </div>
  )
}

export default FilterContent