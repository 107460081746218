import React, { useContext, useEffect, useState } from 'react'
import './searchpage.styles.scss'
import { ChipComponent, SearchFilterInput, ImageCard, BottomDrawer, Loader, BackButton } from '../../components'
import { BiSolidHot } from 'react-icons/bi'
import FilterContent from './FilterContent'
import { fetchRecentSearches, fetchTrendingStates, placeBookmarkStatus, search } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { StateContext } from '../../context/stateContext'

const EVENT_CARD_STYLE = {
  maxHeight:180, minHeight:180, maxWidth:120, minWidth:120, margin:'5px', padding:12, borderRadius:10
}

const ImageCardMap = ({items, searchResulText}) => {
  
  return (
    <>
      {items.map (item => (
        <ImageCard 
          style={{...EVENT_CARD_STYLE, backgroundImage:`url(${item.image})`}} 
          key={item.id}
          _id={item.id}
          redirectText='/details'
          metaRedirectText={{"id":item.id}}
          placeName={item.name} 
          guidesAvailable='logic req. '
          bookmarked={item.isSaved}
          bookmarkFunction={placeBookmarkStatus}
          comingFromSearch={true}
          searchText={searchResulText}
        />
      ))}
    </>
  )
}

const SearchPage = () => {
  const {darkMode} = useContext(StateContext)
  const [showDrawer, setShowDrawer] = useState(false)
  const [recentSearches, setRecentSearches] = useState([])
  const [trendingStates, setTrendingStates] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchResulText, setSearchResulText] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { notify } = useNotify()

  const searchHandler = (stateName) => {
    if(!stateName && searchText.length < 3){
      return notify('Please enter valid city or guide name to search.')
    }
    setLoading(true)
    search(stateName || searchText).then(res => {
      setData(res.data)
      setSearchResulText(stateName || searchText)
    }).catch(err => {
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }

  useEffect(()=>{
    fetchRecentSearches().then(res => {
      setRecentSearches(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })

    fetchTrendingStates().then(res => {
      setTrendingStates(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  //eslint-disable-next-line
  },[])

  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
        <BackButton />
        <SearchFilterInput 
          value={searchText}
          onChange={e=>setSearchText(e.target.value)} 
          // showSearchIcon={searchText.length >= 3} 
          showSearchIcon={false} 
          searchHandler={searchHandler}
          onClick={()=>setShowDrawer(!showDrawer)} 
        />
        {loading ?
          <Loader />  
          :
          <>
          <div className={`search-container ${showDrawer ? 'bottom-active' : ''}`}>

            <p className='suggestion-text dark-mode-text'>Try searching for <span className='dark-mode-text'>'Ooty'</span></p>

            {/* Trending Places */}
            <div className='icon-text-container'>
              <BiSolidHot />
              <p className='trending-places-text dark-mode-text'>Trending Places</p>
            </div>

            {/* Chip container */}
            <div className='chip-container'>
              {trendingStates.map(item => {
                return (
                  <ChipComponent key={item.id} text={item.stateName} style={{padding:8, fontSize:14}} onClick={()=>searchHandler(item.stateName)}/>
                )
              })}
            </div>
            {data.length === 0 && searchResulText.length === 0 ?
              <p className='recent-text'>Your Recent Searches</p>
              :
              <p className='recent-text'>{data.length} Search result found for <b>"{searchResulText}"</b></p>
            }

            {/* Recent searches card container */}
            <div className='recent-searches-card-container'>
              <ImageCardMap searchResulText={searchResulText} items={data.length > 0  ? data : searchResulText.length > 0 ? [] : recentSearches} />
            </div>
          </div>
          </>
        }
        <BottomDrawer 
          state={showDrawer}
          content={<FilterContent setData={setData} onClick={()=>setShowDrawer(!showDrawer)} />}
        />
    </div>
  )
}

export default SearchPage