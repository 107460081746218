import React, { useContext, useEffect, useRef, useState } from 'react'
import './basicbookingform.styles.scss'
import ButtonComponent from '../Button/ButtonComponent'
import { design, namaste } from '../../assets/images'
import IconDropDown from '../IconDropDown'
import { PiBabyThin, PiCalendarBlankThin, PiClockThin, PiTimerThin, PiUserThin, PiXBold } from 'react-icons/pi'
import { bookGuide, GeneralSocket, ChatSocket, fetchDuration, authAxios, updateTourAcceptanceStatus } from '../../utils/service'
import { useNotify } from '../Toast'
import constants from '../../constants'
import Modal from '../Modal'
import RazorpayComponent from '../RazorpayComponent'
import { url } from '../../config'
import { StateContext } from '../../context/stateContext'
import { useNavigate } from 'react-router-dom'
import { RadioInput } from '../UserImageCard'

const BasicBookingForm = ({fullName, image, onClick, bookingData, bookingId, user1_id, user1_username, guideId, guideUsername, requestPersonalizedTourFunction, duration, comingFromGDpage}) => {
	const generalSocketRef = useRef()
	const navigate = useNavigate()
	const {notify} = useNotify()
	const chatSocketRef = useRef()
	const [chargesData, setChargesData] = useState([])
	const [selectedDuration, setSelectedDuration] = useState(duration?.hour || '3h')
	const [reasonText, setReasonText] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [showCancelModal, setShowCancelModal] = useState(false)
	const [price, setPrice] = useState(0)
	const [showOptions, setShowOptions] = useState(true)
	const [showText, setShowText] = useState(false)
	const [basicFormId, setBasicFormId] = useState(undefined)
	const pathname = window.location.pathname
	const {darkMode} = useContext(StateContext)

	useEffect(()=>{
		if(duration){
			setSelectedDuration(duration?.hour)
		}
	},[duration])

	useEffect(()=>{
		if(guideId){
			fetchDuration(guideId).then(res =>{
				setChargesData(res.data)
			}).catch(err => {
				notify('error while fetching duration data.')
			})
		}
		// eslint-disable-next-line
	},[guideId])

	function getNext14Days() {
		const options = [];
		const today = new Date();

		for (let i = 0; i < 15; i++) {
			const nextDay = new Date(today);
			nextDay.setDate(today.getDate() + i);

			const dayOfWeek = nextDay.toLocaleDateString('default', { weekday: 'short' }); // Get first 3 letters of the day
			let day = nextDay.getDate();
			if(String(day).length === 1){
				day = '0' + day;
			}

			const month = nextDay.toLocaleString('default', { month: 'short' });
			const labelText = `${day} ${month} (${dayOfWeek})`;
			const valueText = `${nextDay.getFullYear()}-${nextDay.getMonth()+1}-${day}`
			const entry = {'value':valueText, 'label':labelText}
			options.push(entry);
		}

		return options;
	}

	const [data, setData] = useState({
		"date": getNext14Days()[0].value,
		"time": "10:00:00",
		"adults" : 1,
		"childrens" : 0
	})

	const resetHandler = () =>{
		setData({
		"date": getNext14Days()[0].value,
		"time": "10:00:00",
		"adults" : 1,
		"childrens" : 0
		})
		setSelectedDuration('3h')
	}

	const confirmHandler = () => {

		if(String(data.adults) === '0' && String(data.childrens) === '0'){
			return notify('please choose minimum 1 adult or children')
		}

		const isUpdate = bookingId ? true : false
		const isPersonalizedTour = requestPersonalizedTourFunction ? true : false
		bookGuide({...data, isUpdate, isPersonalizedTour, bookingId, guide:guideUsername, duration:selectedDuration}).then(async res => {

			// store the basic form id coming from response
			setBasicFormId(res.data.basicFormId)
			const basicFormId = res.data.basicFormId
			const bankDetailsVerified = res.data.bankDetailsVerified
			// for room name
			// const roomName = getRoomName(user1_id, user1_username, guideId, guideUsername)
			
			authAxios.post(`${url}/auth/api/create_or_get_chat_room/`, { userId:guideId }).then(res =>{

				const roomName = res.data.room_name;
				// Message for guide in message
				let formattedDate = getNext14Days().find(item => item.value === data.date)
				formattedDate = formattedDate ? formattedDate.label.split('(')[0] + data.date.split('-')[0] : formattedDate
				
				const message = `Hey ${guideUsername}👋\nYou have ${isUpdate ? 'one updated' : requestPersonalizedTourFunction ? 'personalized': 'new'} booking from ${String(user1_username)?.toLocaleUpperCase()}\n<b>Booking Details:</b>\nAdults : ${data.adults}\nChildrens : ${data.childrens}\nDate : ${formattedDate}\nTime: ${data.time}\nDuration: ${selectedDuration || duration?.hour} `
				
				if(requestPersonalizedTourFunction){
					requestPersonalizedTourFunction(basicFormId, isUpdate)
					navigate(-1)
				}
				
				setTimeout(()=>{
					if(selectedDuration && !isUpdate && bankDetailsVerified){
						const record = chargesData.filter(charge => charge?.hour === selectedDuration)
						setPrice(record?.[0].price)
						setModalIsOpen(true)
						window.history.replaceState({}, '')
						resetHandler()
					}
				},1000)
				
				// two web sockets one for message and one for notification
				GeneralSocket(guideId, user1_username, generalSocketRef, message, constants.bookingAndNotificationAndMessage)
				ChatSocket(roomName, user1_username, message, chatSocketRef)
	
				// Notifications
				if(!requestPersonalizedTourFunction){
					notify('Thank You! Booking request sent.\nOur Host will respond soon.')
				}
				if(isUpdate && onClick){
					onClick()
				}
			})

		}).catch(err =>{
			notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
		})
	}

	const tourCancelHandler = () =>{
		if(reasonText.length < 10){
		  return notify('please enter a valid reason')
		}
		updateTourAcceptanceStatus(bookingData?._id, 'cancel', reasonText).then(res => {
			notify('Status updated')
			GeneralSocket(bookingData?.guideId, bookingData?.guideUsername, generalSocketRef, 'Tourist has cancelled the tour.', constants.bookingAndNotification)
			setShowCancelModal(false)
			navigate('/', { replace: true });
			navigate('/Calendar')
		}).catch(err =>{
		  notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
		  setShowCancelModal(false)
		})
	}

	useEffect(()=>{
		if(!bookingData){
			return
		}
		if(Object.keys(bookingData).length <= 0){
			return
		}
		if(bookingData){
			setData({
				"date": bookingData?.date,
				"time": bookingData?.time,
				"adults" : bookingData?.adults,
				"childrens" : bookingData?.childrens
			  })
		}
		// eslint-disable-next-line
	},[bookingData])

	return (
		<div className={`${darkMode ? 'dark-mode' : ''}`}>
			{modalIsOpen &&
				<Modal isOpen={modalIsOpen} onClose={()=>{setModalIsOpen(false);if(onClick){onClick()}}}>
					<div className='d-flex column items-center px-3'>
						<h1 className='fw-500 fs-18'>Congratulations ! 🎉</h1>
						<h4 className='fw-400 my-10'>Your tour has been booked, for confirmation from your end you can pay 20% of your tour amount. {price}</h4>
						<RazorpayComponent guideId={guideId} price={price || duration?.price} id={duration?.id} buttonText={`Pay 20 % ${price ? price * 0.20 : duration?.price * 0.20}`} basicFormId={basicFormId} hideModal={onClick}/>
					</div>

				</Modal>
			}
			<div className='card-information-container'>
				{showCancelModal &&
					<Modal hideX={true} isOpen={showCancelModal} onClose={()=>{setShowCancelModal(false);setShowText(false)}}>
						<>
							{showOptions ?
								<>
									<div style={{marginTop:-20}} className='d-flex px-10'>
										<div>
											<RadioInput onClick={()=>{setShowText(false);setReasonText('Booked by mistake')}} text='Booked by mistake' />
											<RadioInput onClick={()=>{setShowText(false);setReasonText("I'm unavailable")}} text="I'm unavailable" />
											<RadioInput onClick={()=>{setShowText(false);setReasonText('Guide requested to cancel')}} text='Guide requested to cancel' />
											<RadioInput onClick={()=>{setShowText(false);setReasonText('User not responding')}} text='User not responding' />
											<RadioInput onClick={()=>{setShowText(false);setReasonText('Social issues')}} text='Social issues' />
											<RadioInput onClick={()=>{setShowText(true);setReasonText('')}} text=' Other (in few words)' />
											{showText &&
												<textarea placeholder='Type here...' value={reasonText} onChange={(e)=>setReasonText(e.target.value)} className='textarea-image-card' rows={5}></textarea>
											}
										</div>
									</div>
								</>
								:
								<>
									
								</>
							}
							<div className='d-flex w-100 space-between'>
								<ButtonComponent onClick={()=>setShowCancelModal(false)} buttonTextColor='black' wrapperContainerStyle={{width:"100%", height:'30px', padding:9, margin:"10px",  backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} title='Cancel' />
								<ButtonComponent onClick={()=>{tourCancelHandler(reasonText);setShowCancelModal(false);setReasonText('')}} wrapperContainerStyle={{width:"100%", height:'30px', padding:'10px 12px', margin:"10px",}} title='Send' />
							</div>
						</>
					</Modal>
				}
				{/* guide image */}
				<img className='guide-image' src={image} alt='Guide Name' />
				<div>
					{/* namaste */}
					<img className='namaste' src={namaste} alt='namaste' />
					{/* name */}
					<h2 className='title-text dark-mode-gray-text'>I am {fullName}</h2>
				</div>

				{/* indiaFort image */}
				<p></p>
				<img className='india-fort' src={design} alt='IndiaFort' />
			</div>

			{/* Text and dropdown container*/}
			<div className='px-10'>
				<p className='suggestion-text dark-mode-gray-text'>Tell me your preferred date and group size</p>
			</div>

			{/* Dropdowns */}
			<div className='dropdown-container-pt'>
				<IconDropDown Icon={PiCalendarBlankThin}>
					<select value={data.date} onChange={(e)=>setData({...data, date:e.target.value})}>
						{getNext14Days().map(item => {
							return(
								<option key={item.value} value={item.value}>{item.label}</option>
							)
						})}
					</select>
				</IconDropDown>

				<IconDropDown Icon={PiClockThin}>
					<select value={data.time} onChange={(e)=>setData({...data, time:e.target.value})}>
						<option value='06:00:00'>06:00 am IST</option>
						<option value='07:00:00'>07:00 am IST</option>
						<option value='08:00:00'>08:00 am IST</option>
						<option value='09:00:00'>09:00 am IST</option>
						<option value='10:00:00'>10:00 am IST</option>
						<option value='11:00:00'>11:00 am IST</option>
						<option value='12:00:00'>12:00 pm IST</option>
						<option value='01:00:00'>01:00 pm IST</option>
						<option value='02:00:00'>02:00 pm IST</option>
						<option value='03:00:00'>03:00 pm IST</option>
						<option value='04:00:00'>04:00 pm IST</option>
						<option value='05:00:00'>05:00 pm IST</option>
						<option value='06:00:00'>06:00 pm IST</option>
					</select>
				</IconDropDown>

				<IconDropDown Icon={PiUserThin}>
					<select value={data.adults} onChange={(e)=>setData({...data, adults:e.target.value})}>
						<option value={1}>1 Adult</option>
						<option value={2}>2 Adults</option>
						<option value={3}>3 Adults</option>
						<option value={4}>4 Adults</option>
						<option value={5}>5 Adults</option>
						<option value={6}>6 Adults</option>
						<option value={7}>7 Adults</option>
						<option value={8}>8 Adults</option>
						<option value={9}>9 Adults</option>
						<option value={10}>10 Adults</option>

					</select>
				</IconDropDown>

				<IconDropDown Icon={PiBabyThin}>
					<select value={data.childrens} onChange={(e)=>setData({...data, childrens:e.target.value})}>
						<option value={0}>0 Childrens</option>
						<option value={1}>1 Children</option>
						<option value={2}>2 Childrens</option>
						<option value={3}>3 Childrens</option>
						<option value={4}>4 Childrens</option>
						<option value={5}>5 Childrens</option>
						<option value={6}>6 Childrens</option>
						<option value={7}>7 Childrens</option>
						<option value={8}>8 Childrens</option>
						<option value={9}>9 Childrens</option>
						<option value={10}>10 Childrens</option>
					</select>
				</IconDropDown>
				<IconDropDown Icon={PiTimerThin} style={{minWidth:"88%"}}>
					<select value={selectedDuration} onChange={(e)=>setSelectedDuration(e.target.value)}>
						{chargesData?.map((item, index) => {
							return(
								<option key={index} value={item?.hour}>Duration : {item.hour?.replace('h', '')} Hours</option>
							)
						})}
					</select>
				</IconDropDown>
			</div>
			{/* Buttons */}
			{pathname === '/Personalized-Tour' &&
				<button style={{height:0, width:0}} id='basic-booking-form' onClick={()=>confirmHandler()}></button>
			}
			{pathname !== '/Personalized-Tour' &&
				<div className='d-flex space-evenly w-100 items-center'>
					<ButtonComponent onClick={()=>resetHandler()} buttonTextColor='gray' wrapperContainerStyle={{width:'40%', padding:'13px', margin:'5px', backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)"}} title='Reset' />
					<ButtonComponent  wrapperContainerStyle={{width:'40%', padding:'13px', margin:'5px', backgroundImage:`url('${design}')`, backgroundRepeat:"no-repeat", backgroundPosition:"center 2px", backgroundColor:"var(--primary)"}} onClick={()=>confirmHandler()} title='Confirm' />
				</div>
			}

			{bookingData?.guideId &&
				<div className='d-flex space-evenly'>
					<ButtonComponent title='Cancel Tour' wrapperContainerStyle={{width:'90%', marginTop:5}} onClick={()=>{setShowCancelModal(true);setShowOptions(true)}}/>
				</div>
			}

			{onClick &&
				<>
					{bookingData?.guideId ?
						<div className='d-flex items-center content-center column bg-white border-rounded border position-top-right' style={{borderRadius:'50%', height:20, width:20, padding:10, rotate:"178deg"}}>
							<PiXBold style={{rotate:"2deg"}} size={20} color='black' onClick={()=>navigate("/")}/>
						</div>
						:
						<div className='d-flex space-evenly'>
							<ButtonComponent title='Cancel' wrapperContainerStyle={{width:'90%', marginTop:5}} onClick={onClick}/>
						</div>
					}
				</>
			}
		</div>
	)
}

export default BasicBookingForm
